import { Component, OnInit } from "@angular/core";

@Component({
    selector: "shopping-cart-items",
    templateUrl: "./shopping-cart-items.component.html",
    styleUrls: ["./shopping-cart-items.component.scss"],
})
export class ShoppingCartItemsComponent implements OnInit {
    orderList = [];

    constructor() {}

    ngOnInit(): void {
        if (localStorage.getItem("orderList")) {
            this.orderList = JSON.parse(localStorage.getItem("orderList"));
        }
        console.log("hello");
    }

    deleteOrder = (index) => {
        this.orderList.splice(index, 1);
        localStorage.setItem("orderList", JSON.stringify(this.orderList));
    };
}
