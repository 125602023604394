<div style="width: 100%">
    <div class="page-layout carded fullwidth inner-scroll">
        <!-- CENTER -->
        <div class="center">
            <!-- HEADER -->
            <div
                class="header"
                fxLayout="column"
                fxLayoutAlign="center center"
                fxLayout.gt-xs="row"
                fxLayoutAlign.gt-xs="space-between center"
            >
                <!-- APP TITLE -->
                <div
                    class="logo mb-24 mb-sm-0"
                    fxLayout="row"
                    fxLayoutAlign="start center"
                >
                    <mat-icon
                        class="logo-icon s-32 mr-16"
                        [@animate]="{
                            value: '*',
                            params: { delay: '50ms', scale: '0.2' }
                        }"
                    >
                        shopping_cart
                    </mat-icon>
                    <span
                        class="logo-text h1"
                        [@animate]="{
                            value: '*',
                            params: { delay: '100ms', x: '-25px' }
                        }"
                    >Mes Commandes</span
                    >
                </div>
                <!-- / APP TITLE -->

                <button
                    mat-raised-button
                    type="button"
                    color="primary"
                    [routerLink]="'/owner/services'"
                >
                    + Commander un service
                </button>
            </div>
            <!-- / HEADER -->

            <div>
                <table
                    mat-table
                    [dataSource]="dataSource"
                    class="mat-elevation-z8"
                    style="width: 100%"
                >

                    <ng-container matColumnDef="service_name">
                        <th mat-header-cell *matHeaderCellDef>
                            Nom du service
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.service_name }}
                        </td>
                    </ng-container>


                    <ng-container matColumnDef="service_property">
                        <th mat-header-cell *matHeaderCellDef>Nom du bien</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.service_property }}
                        </td>
                    </ng-container>


                    <ng-container matColumnDef="approved">
                        <th mat-header-cell *matHeaderCellDef>Approuvé</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.approved }}
                        </td>
                    </ng-container>


                    <ng-container matColumnDef="company_name">
                        <th mat-header-cell *matHeaderCellDef>Entreprise</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.company_name }}
                        </td>
                    </ng-container>


                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef>Actions</th>
                        <td mat-cell *matCellDef="let element">

                            <button mat-stroked-button color="primary">
                                <mat-icon class="actions-icon">edit</mat-icon>
                            </button>
                            <button mat-stroked-button color="primary" >
                                <mat-icon class="actions-icon"
                                >delete_forever
                                </mat-icon
                                >
                            </button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr
                        class="example-element-row"
                        mat-row
                        *matRowDef="let row; columns: displayedColumns"
                    ></tr>
                </table>
            </div>
        </div>
    </div>
</div>
