import { Component, Inject, ViewEncapsulation } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: "add-visite",
    templateUrl: "./add-visite.component.html",
    styleUrls: ["./add-visite.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class AddVisiteComponent {
    visiteForm: FormGroup;

    constructor(
        public matDialogRef: MatDialogRef<AddVisiteComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any
    ) {
        this.visiteForm = this.createEventForm();
    }

    createEventForm(): FormGroup {
        return new FormGroup({
            title: new FormControl(),
            start: new FormControl(),
        });
    }
}
