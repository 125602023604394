import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import {Service} from '../../models/service.model';


@Component({
    selector: 'service-item',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['service-item.component.scss'],
    templateUrl: 'service-item.component.html',

})
export class ServiceItemComponent  {
    @Input() service: Service;

    constructor(public router: Router){}

}
