<div class="dialog-container service-dialog-container">
    <h1 mat-dialog-title style="display: flex; justify-content: space-between">
        <div>Service {{data.serviceName}}</div>
        <div>Prix: 190£</div>

    </h1>


    <div class="service-dialog-content">
        <div>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse tortor nibh, convallis sed purus nec, auctor
                venenatis nisl. Suspendisse potenti. Nullam sagittis
                nulla in diam finibus, sed pharetra velit vestibulum.
                Suspendisse euismod in urna eu posuere.
            </p>


            <p *ngIf="data.serviceName=='Séance Photo' || data.serviceName=='Reception et filtre des appels'">
                Nunc vel lacinia lorem. Nullam tincidunt sed purus
                eu placerat. Donec id dictum erat. Etiam enim ex,
                dapibus et tortor id, posuere pretium est. Maecenas
                fringilla ipsum vitae neque elementum, at eleifend
                ante sollicitudin. Donec viverra augue dolor, a
                venenatis tellus consectetur sit amet.
            </p>


            <carousel *ngIf="data.serviceName=='Séance Photo'" height="200" cellWidth="90" cellsToShow="3" style="margin:20px 0px">
                <div *ngFor="let photoName of photos; let index = index"
                     class="carousel-cell slider-card"
                     [style]="'width: 90px; transform: translateX(' + index *120 + 'px);'"
                     [class.mat-elevation-z8]="true"
                     [style.background-image]="'url(/assets/images/app-images/' + photoName + ')'">


                </div>

            </carousel>

            <div fxLayout="row" fxLayoutAlign="center center" *ngIf="data.serviceName=='Home Staging'" >
                <img [src]="'assets/images/app-images/home-staging.jpg'" style="width:500px; height:250px; margin:20px">

            </div>



            <div *ngIf="data.serviceName=='Visite Virtuelle'" >
                <iframe src="https://my.matterport.com/show/?m=wWcGxjuUuSb&amp;utm_source=hit-content-embed" allowfullscreen="" height="400" width="690"></iframe>
            </div>




            <img *ngIf="data.serviceName=='Reception et filtre des appels'"
                class="mt-24 w-100-p"
                src="https://bs-solution.com/uploads/blog/gestion-des-appels.jpeg"
                style="max-width: 400px"
            />


            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse tortor nibh, convallis sed purus nec, auctor
                venenatis nisl. Suspendisse potenti. Nullam sagittis
                nulla in diam finibus, sed pharetra velit vestibulum.
                Suspendisse euismod in urna eu posuere.
            </p>

            <p>
                <mat-checkbox style="font-weight: bold" [(ngModel)]="checked">Je veux commander le service</mat-checkbox>

            </p>

        </div>


        <div fxLayout="row" fxLayoutAlign="end center" >
            <button mat-button mat-dialog-close class="mr-8" style="margin-bottom: 20px">Annuler</button>
            <button mat-raised-button="mat-raised-button" type="button"
                    color="accent" style="margin-bottom: 20px"
                    [disabled]="!checked"
                    (click)="openOrderConfirmationDialog(); dialogRef.close()"
                    disabled="false">
                <mat-icon class="logo-icon">shopping_cart</mat-icon>
                Ajouter au panier
            </button>
        </div>




    </div>




</div>