<div class="visiteur-biens-content">
    <div class="search-content">
        <div class="filter-content">
            <h2>Recherche</h2>

            <form fxLayout="column">
                <div fxFlex="1 0 auto" fxLayout="column">
                    <mat-form-field appearance="outline" fxFlex="100">
                        <mat-label>Keyword</mat-label>
                        <input
                            matInput
                            name="keyword"
                        />
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex="100">
                        <mat-label>Localisation</mat-label>
                        <mat-select name="local">
                            <mat-option>None</mat-option>
                            <mat-option
                                *ngFor="let local of locals"
                                [value]="local"
                                >{{ local }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex="100">
                        <mat-label>Type</mat-label>
                        <mat-select name="type">
                            <mat-option
                                *ngFor="let type of types"
                                [value]="type"
                                >{{ type }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex="100">
                        <mat-label>Prix</mat-label>
                        <input matInput />
                    </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutAlign="center center">
                    <button
                        mat-raised-button
                        type="button"
                        color="accent"
                        style="width: 100%"
                    >
                        Chercher
                    </button>
                </div>
            </form>
        </div>

        <div class="biens-result-filter">
            <div class="biens-sort">
                <div fxLayout="row" fxLayoutAlign="end center">
                    <mat-form-field
                        appearance="outline"
                        fxFlex="30"
                        class="my-select"
                    >
                        <mat-label>Trier par</mat-label>
                        <mat-select>
                            <mat-option value="Par defaut">
                                Par defaut
                            </mat-option>
                            <mat-option value="Prix le plus bas">
                                Prix le plus bas
                            </mat-option>
                            <mat-option value=" Prix le plus élevé">
                                Prix le plus élevé
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div>
                <div class="list-bien">
                    <div
                        *ngFor="let bien of biens"
                        class="bien-serach-card"
                        [routerLink]="['/visitor/property/', bien.id]"
                    >
                        <div class="bien-img-container">
                            <img
                                class="bien-img"
                                [src]="
                                    'assets/images/app-images/' + bien.photos[0]
                                "
                            />
                        </div>

                        <div class="bien-card-infos">
                            <div>
                                <p class="bien-type">{{ bien.type }}</p>
                                <p class="bien-title">{{ bien.title }}</p>
                                <p class="bien-address">
                                    <mat-icon>location_on</mat-icon>
                                    {{ bien.address }}, {{ bien.region }}
                                </p>
                                <!--
                                <div class="propio-infos">
                                    <img class="profile-img" [src]="'assets/images/property-list/' + bien.User.photo ">

                                    <p>{{bien.User.firstName}} {{bien.User.lastName}}</p>

                                </div>
                                -->
                            </div>
                            <div class="bien-price">{{ bien.price }} 000 £</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
