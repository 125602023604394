<h1 mat-dialog-title *ngIf="orderList.length != 0">  Votre Panier:</h1>
<div mat-dialog-content class="property-table-container" *ngIf="orderList.length != 0">
    <table class="property-table">
        <tr class="table-row-titles">
            <td>Image</td>
            <td>Service</td>
            <td>Date commande</td>
            <td>Prix</td>
            <td>Action</td>
        </tr>
        <tr
            *ngFor="let service of orderList; let index = index;"
            class="table-row-properties"
        >
            <td >
                <img
                    class="property-img"
                    [src]="service?.fileToUpload"
                />
            </td>


            <td>{{service?.name}}</td>

            <td>05 Avril 2021</td>

            <td>190 £</td>

            <td>
                <!--
                <button mat-stroked-button color="primary">
                    <mat-icon class="actions-icon">edit</mat-icon>
                </button>-->
                <button mat-stroked-button color="primary" (click)="deleteOrder(index)">
                    <mat-icon class="actions-icon"
                    >delete_forever</mat-icon
                    >
                </button>
            </td>
        </tr>
    </table>
    <div class="cart-total">
        <p>Total:</p>
        <p>190 £</p>
    </div>
</div>

<!-------------->
<h1 mat-dialog-title *ngIf="orderList.length == 0">  Votre panier est vide</h1>
<div *ngIf="orderList.length == 0" style="width:500px;">
    <div mat-dialog-content>Vous pouvez consulter nos services et ajouter des commmanes au panier</div>

    <div fxLayout="row" fxLayoutAlign="end end" style="margin-top:50px">
        <button mat-button mat-dialog-close style="background-color: #1ec501; color:white">Ok</button>
    </div>
</div>