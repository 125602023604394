<div class="visiteur-biens-content">
    <div class="images-slider"></div>

    <div class="details-content">
        <div class="details-titles">
            <div class="details-title-address">
                <h1 class="bold">{{bien.typeofprop }}</h1>
                <p>{{bien.address.street }}, {{ bien.address.city }}</p>
            </div>
            <div class="details-price-buttons">
                <h1 class="bold">{{ bien.price }}€</h1>
            </div>
        </div>

        <div class="details-description-contact">
            <div class="details-desription">
                <h2 class="bold">Description</h2>
                <p class="text">
                    {{ bien.description }}
                </p>

                <hr />

                <h2 class="bold">Details</h2>

                <div class="details-table-content">
                    <table>
                        <tr>
                            <td>Type:</td>
                            <td class="details-table-item bold">
                                {{ bien.type }}
                            </td>
                            <td>Chambre:</td>
                            <td class="bold">{{ bien.nbRooms }}</td>
                        </tr>
                        <tr>
                            <td>Surface:</td>
                            <td class="bold">{{ bien.allArea }} m²</td>
                            <td>Salles de bains:</td>
                            <td class="bold">{{ bien.baths }}</td>
                        </tr>
                        <tr>
                            <td>Adresse:</td>
                            <td class="details-table-item bold">
                                {{bien.address.street }}, {{ bien.address.city }}
                            </td>
                            <td>Année de construction:</td>
                            <td class="bold">{{ bien.yearBuilt }}</td>
                        </tr>
                        <tr>
                            <td>Prix:</td>
                            <td class="details-table-item bold">
                                {{ bien.price }}€
                            </td>
                            <td>Statut:</td>
                            <td class="bold">{{ bien.status}}</td>
                        </tr>
                    </table>
                </div>

                <hr />

                <h2 class="bold">Photos</h2>

                <div class="details-photos">
                    <carousel height="330" cellWidth="330" cellsToShow="2">
                        <div
                            *ngFor="
                                let photoName of bien.mainPic;
                                let index = index
                            "
                            class="carousel-cell slider-card"
                            [style]="
                                'width: 330px; transform: translateX(' +
                                index * 365 +
                                'px);'
                            "
                            [class.mat-elevation-z8]="true"
                            [style.background-image]="
                                'url(/assets/images/app-images/' +
                                photoName +
                                ')'
                            "
                        ></div>
                    </carousel>
                </div>

                <hr />
            </div>

            <div class="details-contact-features">
                <div class="details-contact">
                    <h2 class="bold">
                        Contact {{ user.first_name }} {{ user.last_name }}
                    </h2>
                    <div class="contact-infos">
                        <img
                            class="profile-img"
                            [src]="'assets/images/app-images/' + user.photo"
                        />

                        <div class="contact-infos-details">
                            <p class="bold">
                                {{ user.first_name }} {{ user.last_name }}
                            </p>
                            <p>{{ user.phone_number }}</p>
                            <p>{{ user.email }}</p>
                        </div>
                    </div>

                    <form fxLayout="column">
                        <mat-form-field appearance="outline" fxFlex="100">
                            <mat-label>Nom</mat-label>
                            <input matInput />
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="100">
                            <mat-label>E-mail</mat-label>
                            <input matInput />
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="100">
                            <mat-label>Telephone</mat-label>
                            <input matInput />
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="100">
                            <mat-label>Message</mat-label>
                            <textarea matInput></textarea>
                        </mat-form-field>

                        <button mat-raised-button type="button" color="accent">
                            Envoyer message
                        </button>
                    </form>
                </div>
                <div class="latest-properties"></div>
            </div>
        </div>
    </div>
</div>
