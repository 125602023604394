import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';

import { FuseSharedModule } from '@fuse/shared.module';
import { FuseDemoModule } from '@fuse/components/demo/demo.module';

import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { ServiceOrdersService } from './services/service-orders.service';

import { ServicesListComponent } from './containers/owner/services-list/services-list.component';
import { ServiceDetailComponent } from './containers/owner/service-details/service-details.component';
import { ServiceItemComponent } from './components/service-item/service-item.component';
import { ConditionsDialogComponent } from './components/conditions-dialog/conditions-dialog.component';
import { CreditCardComponent } from './components/credit-card/credit-card.component';
import { PayementComponent } from './components/payment-page/payment-page.component';
import { ServiceOrdersListComponent } from './containers/owner/service-orders-list/service-orders-list.component';
import { MatTableModule } from '@angular/material/table';
import { AuthGuard } from 'app/middlewares/auth.guard';
import {ShoppingCartItemsComponent} from './components/shopping-cart-items/shopping-cart-items.component';

export const ROUTES: Routes = [
    {
        path: 'owner/services',
        component: ServicesListComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'owner/service/:id',
        component: ServiceDetailComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'owner/payement/:id',
        component: PayementComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'owner/service_orders',
        component: ServiceOrdersListComponent,
        canActivate: [AuthGuard],
    },
];

@NgModule({
    declarations: [
        ServicesListComponent,
        ServiceDetailComponent,
        ServiceItemComponent,
        ConditionsDialogComponent,
        CreditCardComponent,
        PayementComponent,
        ServiceOrdersListComponent,
        ShoppingCartItemsComponent
    ],
    imports: [
        ReactiveFormsModule,
        HttpClientModule,
        RouterModule.forChild(ROUTES),
        CommonModule,
        BrowserModule,

        MatIconModule,
        MatButtonModule,
        MatInputModule,
        MatFormFieldModule,
        MatDialogModule,

        TranslateModule,

        FuseSharedModule,
        FuseDemoModule,

        MatTableModule,
    ],
    providers: [ServiceOrdersService],
    exports: [],
})
export class ServiceOrdersModule {}
