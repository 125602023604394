import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { Observable } from "rxjs";

import { Service } from "../models/service.model";
import { environment } from "environments/environment";

@Injectable()
export class ServiceOrdersService {
    constructor(private http: HttpClient) {}
    api = `${environment.apiUrl}/services`;

    getServices(): Observable<Service[]> {
        return this.http.get<Service[]>(`${this.api}`);
    }

    getService(id): any {
        return this.http.get(`${this.api}/${id}`);
    }

    addServiceOrder(order) {
        return this.http.post(`${this.api}`, order);
    };

    getOrders(): Observable<any[]> {
        return this.http.get<any[]>(`http://localhost:8080/commandes`);
    }
}
