<div class="dialog-content-wrapper">
    <mat-toolbar class="mat-accent m-0">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">Ajouter un rendez-vous</span>
            <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>



    <div mat-dialog-content class="p-16 pb-0 m-0" fusePerfectScrollbar>

        <form name="eventForm" [formGroup]="visiteForm" class="event-form w-100-p" fxLayout="column" fxFlex>

            <mat-form-field appearance="outline" class="w-100-p">
                <mat-label>Titre</mat-label>
                <input matInput
                       name="title"
                       formControlName="title"
                       >
            </mat-form-field>


            <mat-form-field appearance="outline" class="pr-sm-8" fxFlex="50">
                <mat-label>Start date</mat-label>
                <input matInput [matDatepicker]="startDatePicker" name="start" formControlName="start">
                <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #startDatePicker></mat-datepicker>
            </mat-form-field>





            <div fxFlex="1 0 auto" fxLayout="column" fxLayout.gt-xs="row">

                <mat-form-field appearance="outline" class="pl-sm-8 no-errors-spacer" fxFlex="50">
                    <mat-label>Start time</mat-label>
                    <input matInput type="time">
                </mat-form-field>

                <mat-form-field appearance="outline" class="pl-sm-8 no-errors-spacer" fxFlex="50">
                    <mat-label>End time</mat-label>
                    <input matInput type="time">
                </mat-form-field>

            </div>


        </form>

    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center">



        <button
                mat-button color="primary"
                class="save-button"
                (click)="matDialogRef.close(visiteForm)"

                aria-label="ADD">
            ADD
        </button>



    </div>
</div>
