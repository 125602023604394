import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {OrderConfirmationDialogComponent} from '../order-confirmation-dialog/order-confirmation-dialog.component';
import {ServiceOrdersService} from '../../../service-orders/services/service-orders.service';


@Component({
    selector: 'property-estimate-dialog',
    templateUrl: './service-dialog.component.html',
    styleUrls: ['./service-dialog.component.scss'],
})
export class ServiceDialogComponent implements OnInit {

    photos = ['img1.jpg', 'img5.jpg', 'img2.jpg', 'img4.jpg', 'img6.jpg'];
    checked = false;
    orderList = [];
    service;



    constructor(
        private servOrders: ServiceOrdersService,
        public dialogRef: MatDialogRef<ServiceDialogComponent>,
        public dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data) {}

    ngOnInit(): void {
        this.servOrders.getService(this.data.serviceId)
            .subscribe(data => {
                this.service = data;
            });


    }


    openOrderConfirmationDialog = ()  => {
        if (localStorage.getItem('orderList')) {
            this.orderList = JSON.parse(localStorage.getItem('orderList'));
        }
        this.orderList.push(this.service)
        localStorage.setItem('orderList', JSON.stringify(this.orderList));
        this.dialog.open(OrderConfirmationDialogComponent);

    }
}
