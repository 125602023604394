import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";

import { Content } from "../../../models/property.model";
import { PropertiesService } from "../../../services/properties.service";
import { fuseAnimations } from "@fuse/animations";
import { FuseConfigService } from "@fuse/services/config.service";

@Component({
    selector: "bien-add",
    templateUrl: "./property-add.component.html",
    styleUrls: ["./property-add.component.scss"],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class PropertyAddComponent implements OnInit {
    // Horizontal Stepper
    horizontalStepperStep1: FormGroup;
    horizontalStepperStep2: FormGroup;
    horizontalStepperStep3: FormGroup;
    horizontalStepperStep4: FormGroup;

    types = ["Appartement", "Maison", "Villa", "Espace commercial"];

    bien: Content;

    public imagePath;

    isShowed = false;

    constructor(
        private _formBuilder: FormBuilder,
        private biensService: PropertiesService,
        private _fuseConfigService: FuseConfigService
    ) {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true,
                },
                toolbar: {
                    hidden: true,
                },
                footer: {
                    hidden: true,
                },
                sidepanel: {
                    hidden: true,
                },
            },
        };
    }

    ngOnInit(): void {
        this.horizontalStepperStep1 = new FormGroup({
            title: new FormControl(),
            description: new FormControl(),
            type: new FormControl(),
            address: new FormControl(),
        });

        this.horizontalStepperStep2 = new FormGroup({
            surface: new FormControl(),
            rooms: new FormControl(),
            baths: new FormControl(),
            yearBuilt: new FormControl(),
        });

        this.horizontalStepperStep4 = new FormGroup({
            price: new FormControl(),
        });
    }

    addBien = () => {
        this.bien = Object.assign(
            {},
            this.horizontalStepperStep1.value,
            this.horizontalStepperStep2.value,
            this.horizontalStepperStep4.value
        );

        this.biensService.addBien(this.bien).subscribe((data) => {
            console.log(data);
        });
    };

    showComment = () => {
        this.isShowed = true;
    };
    hideComment = () => {
        this.isShowed = false;
    };
}
