<div class="visiteur-biens-content">
    <div class="visiteur-biens-banner">
        <div class="banner-content">
            <h1>Trouver Votre Futur Logement !</h1>
            <p>Que cherchez-vous ?</p>

            <div class="banner-search-bar">
                <form>
                    <div class="banner-form" id="step3">
                        <mat-form-field appearance="outline" class="banner-search-input">
                            <mat-label>Entrez un mot-clef</mat-label>
                            <input matInput placeholder="Entrez un mot-clef" />
                            <mat-icon matSuffix>search</mat-icon>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="banner-types-select">
                            <mat-label>Type du bien</mat-label>
                            <mat-select name="type">
                                <mat-option>None</mat-option>
                                <mat-option *ngFor="let type of types" [value]="type">{{ type }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="banner-local-select">
                            <mat-label>Localisation</mat-label>
                            <mat-select name="local">
                                <mat-option>None</mat-option>
                                <mat-option *ngFor="let local of locals" [value]="local">{{ local }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="banner-rooms-select">
                            <mat-label>Chambres</mat-label>
                            <mat-select name="local">
                                <mat-option>None</mat-option>
                                <mat-option *ngFor="let room of rooms" [value]="room">{{ room }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <button mat-raised-button="mat-raised-button" type="button" color="primary"
                            class="visiteur-biens-banner-button" [routerLink]="['/visitor/properties/search']">
                            Chercher
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div class="visiteur-biens-features">
        <h1>Annonces à la une</h1>

        <carousel height="330" cellWidth="330" cellsToShow="4" [autoplay]="true">
            <div *ngFor="let bien of biens; let index = index" class="carousel-cell slider-card" [style]="
                    'width: 330px; transform: translateX(' +
                    index * 365 +
                    'px);'
                " [routerLink]="['/visitor/property/', bien?.id]" [class.mat-elevation-z8]="true"
                [style.background-image]="
                    'url(/assets/images/app-images/' + bien?.mainPic + ')'
                ">
                <div class="slider-card-content">
                    <div class="slider-card-state">{{ bien?.status }}</div>
                    <div class="slider-card-titles">
                        <h2>{{ bien?.price }} €</h2>
                        <p>{{ bien?.typeofprop }}</p>
                        <div class="slider-card-infos">
                            <span>Surface: {{ bien?.allArea }} m²</span>
                            <span>Pièces: {{ bien?.nbRooms }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </carousel>
    </div>
    <div class="page-service">
        <div class="text-center">
            <h2 class="service-heading">Qu'est-ce que tu cherches?</h2>
            <h3 class="service-subheading text-muted">
                Nous fournissons un service complet à chaque étape
            </h3>
        </div>
        <div class="services">
            <div class="row text-center">
                <div class="service-bloc">
                    <span class="fa-stack fa-4x">
                        <i class="fas fa-circle fa-stack-2x text-primary"></i>
                        <i class="far fa-building fa-stack-1x fa-inverse"></i>
                    </span>
                    <h4 class="service-name">Appartement</h4>
                    <p class="text-muted">
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Minima maxime quam architecto quo inventore harum
                        ex magni, dicta impedit.
                    </p>
                </div>
                <div class="service-bloc">
                    <span class="fa-stack fa-4x">
                        <i class="fas fa-circle fa-stack-2x text-primary"></i>
                        <i class="fas fa-home fa-stack-1x fa-inverse"></i>
                    </span>
                    <h4 class="service-name">Maison familiale</h4>
                    <p class="text-muted">
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Minima maxime quam architecto quo inventore harum
                        ex magni, dicta impedit.
                    </p>
                </div>
                <div class="service-bloc">
                    <span class="fa-stack fa-4x">
                        <i class="fas fa-circle fa-stack-2x text-primary"></i>
                        <i class="fas fa-city fa-stack-1x fa-inverse"></i>
                    </span>
                    <h4 class="service-name">Maison de ville</h4>
                    <p class="text-muted">
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Minima maxime quam architecto quo inventore harum
                        ex magni, dicta impedit.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="visiteur-info">
        <div class="visiteur-description">
            <h1>Devenir Agent Immobilier</h1>
            <p>
                Nous travaillons uniquement avec les meilleures entreprises du
                monde entier
            </p>
        </div>
        <button class="visiteur-btn" mat-raised-button="mat-raised-button" type="button">
            <span> S'inscrire maintenant </span>
        </button>
    </div>
</div>