import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';


@Component({
    selector: 'order-confirmation-dialog',
    templateUrl: './order-confirmation-dialog.component.html',
    styleUrls: ['./order-confirmation-dialog.component.scss'],
})
export class OrderConfirmationDialogComponent  implements OnInit{

    orderList = [];

    ngOnInit(): void {
        if (localStorage.getItem('orderList')) {
            this.orderList = JSON.parse(localStorage.getItem('orderList'));
        }
        console.log('hello');

    }
}