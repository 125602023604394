import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "environments/environment";

@Injectable({
    providedIn: "root",
})
export class UserService {
    constructor(private http: HttpClient) {}

    api = `${environment.apiUrl}/users`;

    getUserById(id): any {
        return this.http.get(`${this.api}/${id}`);
    }
}
