import { Component, ViewEncapsulation, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";

import { PropertiesService } from "../../../services/properties.service";
import { fuseAnimations } from "@fuse/animations";
import { FuseConfigService } from "@fuse/services/config.service";
import { Content } from "../../../models/property.model";
import { RootObject } from "../../../models/property.model";


@Component({
    selector: "visitor-home",
    templateUrl: "./visitor-home.component.html",
    styleUrls: ["./visitor-home.component.scss"],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class VisitorHomeComponent implements OnInit {
    types = ["Appartement", "Maison", "Villa", "Espace commercial"];
    locals = ["Atlanta", "Florida", "Orlando", "Miami"];
    rooms = ["+1", "+2", "+3", "+4"];
    Root: RootObject[]= [];
    biens: Content[] = [];
    form: FormGroup;

    constructor(
        private propertiesService: PropertiesService,
        private _fuseConfigService: FuseConfigService
    ) {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true,
                },
                toolbar: {
                    hidden: true,
                },
                footer: {
                    hidden: true,
                },
                sidepanel: {
                    hidden: true,
                },
            },
        };
    }

    ngOnInit(): void {
        this.propertiesService.getBiens().subscribe(
            (response: RootObject) => {
            
            console.log(response.content.length)
            for(let i = 0; i <response.content.length; i++) 
               { this.biens=response.content
                console.log('here')
                console.log(this.biens[i].address)}
            
    },);}
}
