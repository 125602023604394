<div class="page-layout carded fullwidth">
    <!--   <section class="c-wrapper">
       <div class="c-wrapper__row">

       <credit-card></credit-card>
        </div>
    </section>-->

    <h1 class="payment-title">Interface Paiement</h1>

    <div class="payment-container"></div>
</div>
