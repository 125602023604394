export const locale = {
    lang: 'en',
    data: {
        'NAV': {
            'APPLICATIONS': 'Applications',
            'SAMPLE'        : {
                'TITLE': 'Sample',
                'BADGE': '25'
            },
            'SERVICES_Proprietaire'        : {
                'TITLE': 'Services Proprietaire',
                'BADGE': '25'
            }
            // 'SERVICES_Prestataire'        : {
            //     'TITLE': 'Services Prestataire',
            //     'BADGE': '25'
            // }
        }
    }
};
