import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { FuseConfigService } from "@fuse/services/config.service";
import { navigation } from "app/navigation/navigation";
import { MatDialog } from "@angular/material/dialog";
import { LoginComponent } from "app/main/auth/login/login.component";
import { RegisterComponent } from "app/main/auth/register/register.component";
import { Router } from "@angular/router";
import { AuthService } from "app/main/auth/services/auth.service";
import { ShoppingCartItemsComponent } from "../../../main/service-orders/components/shopping-cart-items/shopping-cart-items.component";
import { MatMenuTrigger } from "@angular/material/menu";

@Component({
    selector: "vertical-layout-1",
    templateUrl: "./layout-1.component.html",
    styleUrls: ["./layout-1.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class VerticalLayout1Component implements OnInit, OnDestroy {
    fuseConfig: any;
    navigation: any;
    isLoggedIn = false;
    orderList = [];
    @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        public dialog: MatDialog,
        private _router: Router,
        private authService: AuthService
    ) {
        // Set the defaults
        this.navigation = navigation;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }
    Logout() {
        localStorage.removeItem("user");
        localStorage.removeItem("is_logged_in");
        this.isLoggedIn = false;
        this._router.navigateByUrl("/visitor/home");
    }

    openDialogLogin = () => {
        const dialogRef = this.dialog.open(LoginComponent, {
            panelClass: "my-dialog-login",
        });

        dialogRef.afterClosed().subscribe((result) => {
            console.log(`Dialog Login result: ${result}`);
            this.isLoggedIn = this.authService.isLoggedIn();
        });
    };
    openDialogRegister = () => {
        const dialogRef = this.dialog.open(RegisterComponent, {
            panelClass: "my-dialog-register",
        });

        dialogRef.afterClosed().subscribe((result) => {
            console.log(`Dialog Register result: ${result}`);
            this.isLoggedIn = this.authService.isLoggedIn();
        });
    };

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this.fuseConfig = config;
            });
        this.isLoggedIn = this.authService.isLoggedIn();

        if (localStorage.getItem("orderList")) {
            this.orderList = JSON.parse(localStorage.getItem("orderList"));
        }
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    openCartDialog = () => {
        this.dialog.open(ShoppingCartItemsComponent);
    };

    closeMyMenu() {
        this.trigger.closeMenu();
        console.log("close");
    }
    openMyMenu() {
        this.trigger.toggleMenu();
    }
}
