import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";

import { FuseSharedModule } from "@fuse/shared.module";
import { FuseDemoModule } from "@fuse/components/demo/demo.module";

import { ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";

import { CalendarModule } from "./containers/calendar/calendar.module";

export const ROUTES: Routes = [
    {
        path: "calendar",
        loadChildren: () =>
            import("./containers/calendar/calendar.module").then(
                (m) => m.CalendarModule
            ),
    },
];

@NgModule({
    declarations: [],
    imports: [
        ReactiveFormsModule,
        HttpClientModule,
        RouterModule.forChild(ROUTES),
        CommonModule,
        BrowserModule,

        FuseSharedModule,
        FuseDemoModule,

        CalendarModule,
    ],
    providers: [],
    exports: [],
})
export class VisitesModule {}
