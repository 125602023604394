import { Component } from "@angular/core";
import { FuseConfigService } from "@fuse/services/config.service";

@Component({
    selector: "payment-page",
    templateUrl: "./payment-page.component.html",
    styleUrls: ["./payment-page.component.scss"],
})
export class PayementComponent {
    constructor(private _fuseConfigService: FuseConfigService) {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true,
                },
                toolbar: {
                    hidden: true,
                },
                footer: {
                    hidden: true,
                },
                sidepanel: {
                    hidden: true,
                },
            },
        };
    }
}
