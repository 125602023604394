import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { AuthService } from "app/main/auth/services/auth.service";

@Injectable({
    providedIn: "root",
})
export class AuthGuard implements CanActivate {
    constructor(private _router: Router, private authService: AuthService) {}
    canActivate() {
        if (this.authService.isLoggedIn()) {
            return true;
        }
        this._router.navigateByUrl("/visitor/home");
        return;
    }
}
