import { Component, Directive, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";


import { PropertiesService } from "../../../services/properties.service";
import { fuseAnimations } from "@fuse/animations";
import { FuseConfigService } from "@fuse/services/config.service";
import { MatDialog } from "@angular/material/dialog";
import { PropertyEstimateDialogComponent } from "../../../components/property-estimate-dialog/property-estimate-dialog.component";
import { ServiceDialogComponent } from "../../../components/service-dialog/service-dialog.component";
import { LoginComponent } from "../../../../auth/login/login.component";
import { AuthService } from "app/main/auth/services/auth.service";
import { GooglePlaceDirective } from "ngx-google-places-autocomplete";
import { Address } from "ngx-google-places-autocomplete/objects/address";
import {MatChipInputEvent} from '@angular/material/chips';


export class AdressEmitter {
    street_number: string = '';
    route: string = '';
    name: string = '';
    locality: string = '';
    postal_code: string = '';
    pays: string = '';
    longitude: number = 0;
    latitude: number = 0;
    geometry: any = null;
  }
@Component({
    selector: "property-estimate",
    templateUrl: "./property-estimate.component.html",
    styleUrls: ["./property-estimate.component.scss"],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})

export class PropertyEstimateComponent implements OnInit {
    handeladress :boolean = false ; 
    @ViewChild("placesRef") placesRef : GooglePlaceDirective;
    selectedmaison = false ;
    selectedapp= false
    verticalStepperStep1: FormGroup;
    value ="ngx-places";
    selectedOption : any
    propertyFormGroup: FormGroup;
    types = ["Appartement", "Maison"];
    
    isShowed = false;
    isPriceShowed = false;
    add: string="" ;
    
      
    
    imgUrls = [];
    dialogServRef;
    cd: void;
    formatted_address='';
    adress: AdressEmitter = new AdressEmitter()
    
   
  options = {
    types: [],
    componentRestrictions: { country: 'FR' }
    
  }
  public handleAddressChange(address: Address ) {
    this.handeladress = true;
    this.formatted_address = address.formatted_address
    console.log("address",address);
    console.log(address.geometry.location.lat());
    console.log(address.geometry.location.lng());
    address.address_components.forEach(elem => {
        
        if (elem.types.includes("postal_code")) {
            this.adress.postal_code = elem.long_name
          
          }
          console.log(this.adress.postal_code)
    
    })
    
  
    this.verticalStepperStep1.controls.latAppart.setValue(address.geometry.location.lat());
    this.verticalStepperStep1.controls.lngAppart.setValue(address.geometry.location.lng());
    this.verticalStepperStep1.controls.latMaison.setValue(address.geometry.location.lat());
    this.verticalStepperStep1.controls.lngMaison.setValue(address.geometry.location.lng());
    this.verticalStepperStep1.controls.code.setValue(+this.adress.postal_code)
  

    


  }

    

    constructor(
        private _formBuilder: FormBuilder,
        private biensService: PropertiesService,
        private _fuseConfigService: FuseConfigService,
        private authService: AuthService,
        public dialog: MatDialog
      
    ) {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: { hidden: true },
                toolbar: { hidden: true },
                footer: { hidden: true },
                sidepanel: { hidden: true },
            },
        };
    }

    ngOnInit(): void {
        this.verticalStepperStep1 = new FormGroup({
            
            
          
          
            nbPiecesMaison: new FormControl(),
            nbPieces: new FormControl(),
            nbrRoomMaison: new FormControl(),
            nbrRoom: new FormControl(),
            GarageMaison: new FormControl(),
            JardinMaison:new FormControl(),
            caveMaison:new FormControl(),
            TerasseAppart:new FormControl(),
            GarageAppart:new FormControl(),
            BalconAppart:new FormControl(),
            CaveAppart:new FormControl(),
            Piscine:new FormControl(),
            surfaceTerrain: new FormControl(),
            surfaceMaison: new FormControl(),
            surface: new FormControl(),
            yearBuildMaison: new FormControl(),
            yearBuilt: new FormControl(),
            price: new FormControl(),
            telephone: new FormControl(),
            latAppart:new FormControl(),
            
            lngAppart:new FormControl(),
            latMaison:new FormControl(),
            lngMaison:new FormControl(),
            code:new FormControl(),
            ll:new FormControl(),
            
            
        });
        
       
        
    }
    
    
    choixbien(event)
    {
        console.log ( "event" , event.target.innerText);
        if (event.target.innerText ==="Appartement" )
        {
            this.selectedOption = "Appartement"
        }
       else if (event.target.innerText ==="Maison" )
        {
            this.selectedOption ="Maison"
        }
    }
    showComment = () => {
        this.isShowed = true;
    };
    hideComment = () => {
        this.isShowed = false;
        
    };

    openEstimateDialog = () => {
         if ( this.handeladress)
         {
        const dialogRef = this.dialog.open(PropertyEstimateDialogComponent);
       
        this.authService.send_form(this.verticalStepperStep1.value);
         
      
    
        
1

        dialogRef.afterClosed().subscribe((response) => {
            if (!response) {
                return;
            }

            this.verticalStepperStep1.patchValue({
                price: response,
            });

            this.isPriceShowed = true;
        });
    }};

    openServiceDialog = (serviceName, id, timeout) => {
        setTimeout(() => {
            this.dialogServRef = this.dialog.open(ServiceDialogComponent, {
                data: {
                    serviceName: serviceName,
                    serviceId: id,
                },
            });
        }, timeout);

        this.dialogServRef.afterClosed().subscribe((response) => {
            if (!response) {
                return;
            }
        });
    };
    
    

    onFileChanged = (idImg, files) => {
        if (files.length === 0) {
            return;
        }
        const reader = new FileReader();

        reader.readAsDataURL(files[0]);
        reader.onload = (_event) => {
            this.imgUrls[idImg] = reader.result;
        };
    };

    openDialogLogin = () => {
        const dialogRef = this.dialog.open(LoginComponent, {
            panelClass: "my-dialog-login",
        });

        dialogRef.afterClosed().subscribe((result) => {
            console.log(`Dialog Login result: ${result}`);
        });
    };
}
