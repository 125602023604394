import { HttpClient , HttpHeaders, HttpClientModule  } from "@angular/common/http";
import { Injectable } from "@angular/core";
import axios from "axios";
import { environment } from "environments/environment";
import { User } from "../models/User";
const optionRequete = {
    headers: new HttpHeaders({ 
      'Access-Control-Allow-Origin':'*',
      
    })
  };

@Injectable()
export class AuthService {
    constructor(private http: HttpClient) {}

    api = `${environment.apiUrl}/users`;
    flask=`${environment.flaskUrl}`;
    flaske=`${environment.flaskUrle}`;
    isLoggedin: boolean = false;

    async login(credentials) {
        return await axios.get(
            `${this.api}?email=${credentials.email}&password=${credentials.password}`
        );
    }
    register(user: User) {
        return axios.post(`${this.api}`, user);
    }

    loggedUser(): User {
        return JSON.parse(localStorage.getItem("user")) || false;
    }

    isLoggedIn(): boolean {
        if (JSON.parse(localStorage.getItem("is_logged_in")) || false) {
            return true;
        } else {
            return false;
        }
    }
    

    send_form(data){
        try {
        console.log(data)
          return axios.post(
           this.flask,
            JSON.stringify(data))
        
        
          }catch (error) {
            console.error(error.response.data);    
          }
        }
    
    get_value(){
        try {
        return axios.get(
            ` ${this.flask}`
        );}
        catch (error) {
        console.error(error.response.data);    
      }
    }
           
   
}
