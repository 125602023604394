import { FuseNavigation } from "@fuse/types";

export const navigation: FuseNavigation[] = [
    {
        id: "biens",
        title: "Mes Biens",
        type: "item",
        icon: "home",
        url: "/owner/properties",
    },
    {
        id: "calendrier",
        title: "Mon Calendrier",
        type: "item",
        icon: "calendar_today",
        url: "/calendar",
    },
    {
        id: "service_orders",
        title: "Mes Commandes",
        type: "item",
        icon: "shopping_cart",
        url: "/owner/service_orders",
    },

    {
        id: "services",
        title: "Services",
        type: "item",
        icon: "card_travel",
        url: "/owner/services",
    },
];
