import {
    Component,
    ViewEncapsulation,
    OnInit,
    ViewChild,
    ElementRef,
    AfterViewInit,
} from "@angular/core";
import { FormGroup } from "@angular/forms";

import { PropertiesService } from "../../../services/properties.service";
import { ActivatedRoute } from "@angular/router";
import { UserService } from "../../../services/user.service";
import { fuseAnimations } from "@fuse/animations";
import { FuseConfigService } from "@fuse/services/config.service";

@Component({
    selector: "visiteur-bien-details",
    templateUrl: "./visitor-property-details.component.html",
    styleUrls: ["./visitor-property-details.component.scss"],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class VisitorPropertyDetailsComponent implements OnInit, AfterViewInit {
    types = ["Appartement", "Maison", "Villa", "Espace commercial"];
    idBien;
    bien;
    form: FormGroup;
    user;

    private longitude;
    private latitude;
    private URL =
        "https://maps.google.com/maps?q=15%20rue%20moez&t=&z=13&ie=UTF8&iwloc=&output=embed";
    @ViewChild("gmap_canvas") gmapCanvas: ElementRef;

    constructor(
        private route: ActivatedRoute,
        private propService: PropertiesService,
        private userService: UserService,
        private _fuseConfigService: FuseConfigService
    ) {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true,
                },
                toolbar: {
                    hidden: true,
                },
                footer: {
                    hidden: true,
                },
                sidepanel: {
                    hidden: true,
                },
            },
        };

        navigator.geolocation.getCurrentPosition((position) => {
            sessionStorage.setItem(
                "longitude",
                String(position.coords.longitude)
            );
            sessionStorage.setItem(
                "latitude",
                String(position.coords.latitude)
            );
            return position.coords;
        });
        this.longitude = parseFloat(sessionStorage.getItem("longitude"));
        this.latitude = parseFloat(sessionStorage.getItem("latitude"));
    }

    ngOnInit(): void {
        this.idBien = this.route.snapshot.paramMap.get("id");
        this.propService.getBienById(this.idBien).subscribe((data) => {
            this.bien = data;
            this.userService.getUserById(data.user_id).subscribe((elem) => {
                this.user = elem;
            });
        });
    }

    ngAfterViewInit(): void {
        this.URL =
            "https://maps.google.com/maps?q=36.845863,10.204898&t=&z=15&ie=UTF8&iwloc=&output=embed&maptype=satellite";
        this.gmapCanvas.nativeElement.src = this.URL;
    }
}
