import { Component, OnInit } from "@angular/core";
import { ServiceOrdersService } from "../../../services/service-orders.service";
import { FuseConfigService } from "../../../../../../@fuse/services/config.service";

@Component({
    selector: "services-list",
    templateUrl: "./services-list.component.html",
    styleUrls: ["./services-list.component.scss"],
})
export class ServicesListComponent implements OnInit {
    servicesList = [];

    constructor(
        private serv: ServiceOrdersService,
        private _fuseConfigService: FuseConfigService
    ) {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true,
                },
                toolbar: {
                    hidden: true,
                },
                footer: {
                    hidden: true,
                },
                sidepanel: {
                    hidden: true,
                },
            },
        };
    }

    ngOnInit(): void {
        this.serv.getServices().subscribe((data) => {
            this.servicesList = data;
        });
    }
}
