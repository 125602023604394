import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Content } from '../models/property.model';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root',
})
export class PropertiesService {
    constructor(private http: HttpClient) {}
    token = localStorage.getItem('eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIwMDAwMDAwMCIsImlhdCI6MTYyMjIzNzAzMSwiZXhwIjo5MjIzMzcyMDM2ODU0Nzc1fQ.ESCnlCrRrak6vNCSamXErhw8_K1TUxWZcgfOWZBN6Xqapfdkttiw_9tZx8QEPPR9i7CK3Dc3wbGDFHG5SiA5Kg')
    api = `${environment.apiBack}`;

    getBienById(id: number): any {
        var header = {
            headers: new HttpHeaders()
              .set('Authorization', `Bearer ${'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIwMDAwMDAwMCIsImlhdCI6MTYyMjIzNzAzMSwiZXhwIjo5MjIzMzcyMDM2ODU0Nzc1fQ.ESCnlCrRrak6vNCSamXErhw8_K1TUxWZcgfOWZBN6Xqapfdkttiw_9tZx8QEPPR9i7CK3Dc3wbGDFHG5SiA5Kg' }`)}
         
        return this.http.get(`${this.api}${id}`,header);
    }

    getBiens() {

        var header = {
            headers: new HttpHeaders()
              .set('Authorization', `Bearer ${'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIwMDAwMDAwMCIsImlhdCI6MTYyMjIzNzAzMSwiZXhwIjo5MjIzMzcyMDM2ODU0Nzc1fQ.ESCnlCrRrak6vNCSamXErhw8_K1TUxWZcgfOWZBN6Xqapfdkttiw_9tZx8QEPPR9i7CK3Dc3wbGDFHG5SiA5Kg' }`)}
         
       
       
        
        
        return this.http.get(this.api,header );
    }

    addBien = (property: Content) => {
        return this.http.post(`${this.api}`, property);
    }

    editBien = (property: Content, id: number) => {
        return this.http.put(`${this.api}/${id}`, property);
    }
}
