import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { FuseNavigationService } from "@fuse/components/navigation/navigation.service";
import { AuthService } from "app/main/auth/services/auth.service";

@Injectable()
export class NavigationService {
    constructor(
        private _router: Router,
        private _fuseNavigationService: FuseNavigationService,
        private authService: AuthService,
        public dialog: MatDialog
    ) {}
    redirectSwitchAccount() {
        if (this.authService.isLoggedIn()) {
            this._router.navigateByUrl("/owner/properties");
        }
    }
    changeVisiblilyNav(id, is_hidden) {
        this._fuseNavigationService.updateNavigationItem(id, {
            hidden: is_hidden,
        });
    }
    changeNavVisibilitySwitchUser() {
        if (this.authService.isLoggedIn()) {
            // to hide
            this.changeVisiblilyNav("biens", false);
            this.changeVisiblilyNav("calendrier", false);
            this.changeVisiblilyNav("service_orders", false);
            this.changeVisiblilyNav("services", false);
        }
    }
}
