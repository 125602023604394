<!-- SIDE PANEL -->
<ng-container *ngIf="
        !fuseConfig.layout.sidepanel.hidden &&
        fuseConfig.layout.sidepanel.position === 'left'
    ">
    <!-- PANEL CONTENT -->
</ng-container>
<!-- / SIDE PANEL -->

<div id="main">
    <!-- TOOLBAR: Above -->
    <ng-container *ngIf="fuseConfig.layout.toolbar.position === 'above'">
        <ng-container *ngTemplateOutlet="toolbar"></ng-container>
    </ng-container>
    <!-- / TOOLBAR: Above -->

    <div id="container-1" class="container">
        <!-- NAVBAR: Left -->
        <ng-container *ngIf="fuseConfig.layout.navbar.position === 'left'">
            <ng-container *ngTemplateOutlet="leftNavbar"></ng-container>
        </ng-container>
        <!-- / NAVBAR: Left -->

        <div id="container-2" class="container">
            <!-- TOOLBAR: Below fixed -->
            <ng-container *ngIf="fuseConfig.layout.toolbar.position === 'below-fixed'">
                <ng-container *ngTemplateOutlet="toolbar"></ng-container>
            </ng-container>
            <!-- / TOOLBAR: Below fixed -->

            <div id="container-3" class="container" fusePerfectScrollbar [fusePerfectScrollbarOptions]="{
                    suppressScrollX: true,
                    updateOnRouteChange: true
                }">
                <!-- TOOLBAR: Below static -->
                <ng-container *ngIf="
                        fuseConfig.layout.toolbar.position === 'below-static'
                    ">
                    <ng-container *ngTemplateOutlet="toolbar"></ng-container>
                </ng-container>
                <!-- / TOOLBAR: Below static -->

                <!-- CONTENT -->

                <mat-toolbar>
                    <mat-toolbar-row style="height: 100%">
                        <button mat-icon-button (click)="sidenav.toggle()" fxShow="true" fxHide.gt-sm>
                            <mat-icon>menu</mat-icon>
                        </button>
                        <img class="logo-nav" src="../../../assets/images/logos/oikos_logo.png" alt="" />
                        <img *ngIf="!isLoggedIn" class="btn-login" fxHide.gt-sm (click)="openDialogLogin()"
                            src="../../../../assets/images/avatars/person.png" alt="" />
                        <span class="example-spacer"></span>
                        <div class="navbar" fxFlex fxLayoutAlign="end" fxShow="true" fxHide.lt-md="true">
                            <a mat-button [routerLink]="'/visitor/home'">
                                Accueil
                            </a>
                            <a mat-button [routerLink]="'/visitor/properties/search'">
                                Immobiliers
                            </a>

                            <a style="cursor: pointer" mat-button [matMenuTriggerFor]="menu" (mouseenter)="openMyMenu()"
                                *ngIf="isLoggedIn">
                                Gestion des biens
                            </a>
                            <mat-menu #menu="matMenu" [overlapTrigger]="false">
                                <span (mouseleave)="closeMyMenu()">
                                    <button mat-menu-item [routerLink]="'/owner/properties'">
                                        <mat-icon>home</mat-icon>
                                        <span>Mes biens</span>
                                    </button>
                                    <button mat-menu-item [routerLink]="'/calendar'">
                                        <mat-icon>calendar_today</mat-icon>
                                        <span>Mon Calendrier</span>
                                    </button>
                                    <button mat-menu-item [routerLink]="'/owner/service_orders'">
                                        <mat-icon>shopping_cart</mat-icon>
                                        <span>Mes Commandes</span>
                                    </button>
                                    <button mat-menu-item [routerLink]="'/owner/services'">
                                        <mat-icon>card_travel</mat-icon>
                                        <span>Services</span>
                                    </button>
                                </span>
                            </mat-menu>
                            <a mat-button> A propos </a>
                            <a mat-button> Contact </a>
                            <a mat-button *ngIf="!isLoggedIn" (click)="openDialogLogin()">
                                Connexion
                            </a>
                            <a mat-button *ngIf="!isLoggedIn" (click)="openDialogRegister()">
                                S'inscrire
                            </a>
                            <a mat-button *ngIf="isLoggedIn" (click)="Logout()">
                                Se déconnecter
                            </a>

                            <a mat-button (click)="openCartDialog()" id="step2">
                                <mat-icon>shopping_cart</mat-icon>
                            </a>

                            <a mat-raised-button class="green-50-fg green-bg">
                                <span *ngIf="!isLoggedIn" [routerLink]="'/property/estimate'" id="step1">
                                    Estimer / Créer Bien
                                </span>
                                <span *ngIf="isLoggedIn">
                                    Créer une annonce</span>
                            </a>
                        </div>
                    </mat-toolbar-row>
                </mat-toolbar>

                <mat-sidenav-container style="height: calc(100% - 64px); margin: 0">
                    <mat-sidenav #sidenav>
                        <mat-nav-list>

                            <mat-list-item [routerLink]="'/visitor/home'">
                                <mat-icon>dashboard</mat-icon>
                                Accueil
                            </mat-list-item>
                            <mat-list-item [routerLink]="'/visitor/properties/search'">
                                <mat-icon>local_grocery_store</mat-icon>Immobiliers
                            </mat-list-item>
                            <mat-expansion-panel [class.mat-elevation-z0]="true" dense *ngIf="isLoggedIn">
                                <mat-expansion-panel-header>
                                    Gestion des biens
                                </mat-expansion-panel-header>
                                <mat-nav-list dense>
                                    <mat-list-item [routerLink]="'/owner/properties'">
                                        <mat-icon>home</mat-icon>Mes
                                        biens
                                    </mat-list-item>

                                    <mat-list-item [routerLink]="'/calendar'">
                                        <mat-icon>calendar_today</mat-icon>Mon
                                        Calendrier
                                    </mat-list-item>
                                    <mat-list-item [routerLink]="'/owner/service_orders'">
                                        <mat-icon>shopping_cart</mat-icon>Mes
                                        Commandes
                                    </mat-list-item>
                                    <mat-list-item [routerLink]="'/owner/services'">
                                        <mat-icon>card_travel</mat-icon>
                                        Services
                                    </mat-list-item>
                                </mat-nav-list>
                            </mat-expansion-panel>
                            <mat-list-item routerLink="#">
                                <mat-icon>error_outline</mat-icon>
                                A propos
                            </mat-list-item>
                            <mat-list-item routerLink="#">
                                <mat-icon>local_post_office</mat-icon>Contact
                            </mat-list-item>
                            <mat-list-item>
                                <mat-icon>add_circle_outline</mat-icon>
                                <span *ngIf="!isLoggedIn" [routerLink]="'/property/estimate'">
                                    Estimer Mon Bien
                                </span>
                                <span *ngIf="isLoggedIn">
                                    Créer une annonce</span>
                            </mat-list-item>
                            <mat-list-item *ngIf="isLoggedIn" (click)="Logout()">
                                <mat-icon> exit_to_app</mat-icon> Se
                                déconnecter
                            </mat-list-item>
                            <mat-list-item (click)="sidenav.toggle()">
                                <mat-icon>close</mat-icon> Close
                            </mat-list-item>
                        </mat-nav-list>
                    </mat-sidenav>
                    <mat-sidenav-content fxFlexFill>
                        <content></content>
                        <footer></footer>
                    </mat-sidenav-content>
                </mat-sidenav-container>

                <!-- / CONTENT -->

                <!-- FOOTER: Below static -->
                <ng-container *ngIf="fuseConfig.layout.footer.position === 'below-static'">
                    <ng-container *ngTemplateOutlet="footer"></ng-container>
                </ng-container>
                <!-- / FOOTER: Below static -->
            </div>

            <!-- FOOTER: Below fixed -->
            <ng-container *ngIf="fuseConfig.layout.footer.position === 'below-fixed'">
                <ng-container *ngTemplateOutlet="footer"></ng-container>
            </ng-container>
            <!-- / FOOTER: Below fixed -->
        </div>

        <!-- NAVBAR: Right -->
        <ng-container *ngIf="fuseConfig.layout.navbar.position === 'right'">
            <ng-container *ngTemplateOutlet="rightNavbar"></ng-container>
        </ng-container>
        <!-- / NAVBAR: Right -->
    </div>

    <!-- FOOTER: Above -->
    <ng-container *ngIf="fuseConfig.layout.footer.position === 'above'">
        <ng-container *ngTemplateOutlet="footer"></ng-container>
    </ng-container>
    <!-- FOOTER: Above -->
</div>

<!-- SIDE PANEL -->
<ng-container *ngIf="
        !fuseConfig.layout.sidepanel.hidden &&
        fuseConfig.layout.sidepanel.position === 'right'
    ">
    <!-- PANEL CONTENT -->
</ng-container>
<!-- / SIDE PANEL -->

<!-- QUICK PANEL -->
<fuse-sidebar name="quickPanel" position="right" class="quick-panel">
    <quick-panel></quick-panel>
</fuse-sidebar>
<!-- / QUICK PANEL -->

<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- @ PARTIALS                                                                                            -->
<!-- ----------------------------------------------------------------------------------------------------- -->

<!-- TOOLBAR -->
<ng-template #toolbar>
    <toolbar *ngIf="!fuseConfig.layout.toolbar.hidden" [ngClass]="
            fuseConfig.layout.toolbar.customBackgroundColor === true
                ? fuseConfig.layout.toolbar.position +
                  ' ' +
                  fuseConfig.layout.toolbar.background
                : fuseConfig.layout.toolbar.position
        ">
    </toolbar>
</ng-template>
<!-- / TOOLBAR -->

<!-- FOOTER -->
<ng-template #footer>
    <footer *ngIf="!fuseConfig.layout.footer.hidden" [ngClass]="
            fuseConfig.layout.footer.customBackgroundColor === true
                ? fuseConfig.layout.footer.position +
                  ' ' +
                  fuseConfig.layout.footer.background
                : fuseConfig.layout.footer.position
        "></footer>
</ng-template>
<!-- / FOOTER -->

<!-- LEFT NAVBAR -->
<ng-template #leftNavbar>
    <fuse-sidebar name="navbar" class="navbar-fuse-sidebar" [folded]="fuseConfig.layout.navbar.folded"
        lockedOpen="gt-md" *ngIf="!fuseConfig.layout.navbar.hidden">
        <navbar [variant]="fuseConfig.layout.navbar.variant" class="left-navbar"></navbar>
    </fuse-sidebar>
</ng-template>
<!-- / LEFT NAVBAR -->

<!-- RIGHT NAVBAR -->
<ng-template #rightNavbar>
    <fuse-sidebar name="navbar" position="right" class="navbar-fuse-sidebar" [folded]="fuseConfig.layout.navbar.folded"
        lockedOpen="gt-md" *ngIf="!fuseConfig.layout.navbar.hidden">
        <navbar [variant]="fuseConfig.layout.navbar.variant" class="right-navbar"></navbar>
    </fuse-sidebar>
</ng-template>
<!-- / RIGHT NAVBAR -->