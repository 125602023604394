import {
    Component,
    ViewEncapsulation,
    OnInit,
    ViewChild,
    ElementRef,
    AfterViewInit,
} from "@angular/core";
import { FormsModule, FormGroup } from "@angular/forms";

import { PropertiesService } from "../../../services/properties.service";
import { UserService } from "../../../services/user.service";
import { fuseAnimations } from "@fuse/animations";
import { FuseConfigService } from "@fuse/services/config.service";

@Component({
    selector: "visiteur-biens-search",
    templateUrl: "./visitor-property-search.component.html",
    styleUrls: ["./visitor-property-search.component.scss"],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class VisitorPropertySearchComponent implements OnInit {
    types = ["Appartement", "Maison", "Villa", "Espace commercial"];
    locals = ["Atlanta", "Florida", "Orlando", "Miami"];

    form: FormGroup;
    biens = [];

    constructor(
        private biensService: PropertiesService,
        private userService: UserService,
        private _fuseConfigService: FuseConfigService
    ) {
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true,
                },
                toolbar: {
                    hidden: true,
                },
                footer: {
                    hidden: true,
                },
                sidepanel: {
                    hidden: true,
                },
            },
        };
    }

    ngOnInit(): void {
        this.biensService.getBiens().subscribe((data) => {
            
            console.log(data);
        });
    }
}
