<div class="page-layout carded fullwidth">
    <!-- TOP BACKGROUND -->
    <div class="top-bg"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">
        <!-- CONTENT CARD -->
        <div class="content-card details-container">
            <!-- CONTENT -->
            <div class="content p-24">
                <div class="demo-content line-height-1.75">
                    <div class="details-titles">
                        <h1 class="m-0">{{ service?.name }}</h1>

                        <button
                            mat-raised-button
                            type="button"
                            color="primary"
                            class="add-product-button"
                            (click)="openDialog(); addServiceOrder()"
                        >
                            + Commander
                        </button>
                    </div>
                    <h4 class="mt-0 secondary-text">Demo Content</h4>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Suspendisse tortor nibh, convallis sed purus nec, auctor
                        venenatis nisl. Suspendisse potenti. Nullam sagittis
                        nulla in diam finibus, sed pharetra velit vestibulum.
                        Suspendisse euismod in urna eu posuere.
                    </p>

                    <blockquote class="my-24">
                        <p>
                            Nunc vel lacinia lorem. Nullam tincidunt sed purus
                            eu placerat. Donec id dictum erat. Etiam enim ex,
                            dapibus et tortor id, posuere pretium est. Maecenas
                            fringilla ipsum vitae neque elementum, at eleifend
                            ante sollicitudin. Donec viverra augue dolor, a
                            venenatis tellus consectetur sit amet.
                        </p>
                        <footer>John Doe</footer>
                    </blockquote>

                    <p>
                        Ut ornare sit amet velit vel congue. Ut nec tristique
                        eros. Lorem ipsum dolor sit amet, consectetur
                        <b>adipiscing elit</b>. Vivamus sed lorem quis nibh
                        porta iaculis. Vestibulum ut eleifend ante, at semper
                        mi. Nam imperdiet est nisi, quis hendrerit tellus
                        convallis et. Morbi in luctus neque. Curabitur elementum
                        ut est et gravida. In hac habitasse platea dictumst. In
                        et placerat eros, eu tempor turpis. Curabitur ac felis
                        finibus, elementum lectus vitae, venenatis est. Integer
                        mollis nisl a eros scelerisque varius. Etiam venenatis
                        lectus vel erat condimentum tristique vel vel mi. Nulla
                        id euismod mi, et mollis tellus.
                    </p>

                    <p>
                        Orci varius natoque penatibus et magnis dis parturient
                        montes, nascetur ridiculus mus. Class aptent taciti
                        sociosqu ad litora torquent per conubia nostra, per
                        inceptos himenaeos. Curabitur vitae sagittis odio.
                        Suspendisse ullamcorper nunc non pellentesque laoreet.
                        Curabitur eu tortor id quam pretium mattis. Proin ut
                        quam velit.
                    </p>

                    <img
                        class="mt-24 w-100-p"
                        src="{{ service?.fileToUpload }}"
                        style="max-width: 640px"
                    />

                    <p class="mt-8 mb-24 secondary-text">
                        <em
                            >Nullam tincidunt sed purus eu placerat. Donec id
                            dictum erat. Etiam enim ex, dapibus et tortor
                            id.</em
                        >
                    </p>

                    <p>
                        Quisque sit amet risus enim. Aliquam sit amet interdum
                        justo, at ultricies sapien. Suspendisse et semper urna,
                        in gravida eros. Quisque id nibh iaculis, euismod urna
                        sed, egestas nisi. Donec eros metus, congue a imperdiet
                        feugiat, sagittis nec ipsum. Quisque dapibus mollis
                        felis non tristique.
                    </p>

                    <p>
                        Ut auctor, metus sed dapibus tempus, urna diam auctor
                        odio, in malesuada odio risus vitae nisi. Etiam blandit
                        ante urna, vitae placerat massa mollis in. Duis nec urna
                        ac purus semper dictum ut eget justo. Aenean non
                        sagittis augue. Sed venenatis rhoncus enim eget ornare.
                        <a href="#">Donec viverra sed felis at venenatis.</a>
                        Mauris aliquam fringilla nulla, sit amet congue felis
                        dignissim at.
                    </p>

                    <ul>
                        <li>Orci varius</li>
                        <li>Magnis dis</li>
                        <li>Conubia nostra</li>
                        <li>Semper urna</li>
                        <li>Donec viverra</li>
                    </ul>

                    <p>
                        Quisque accumsan augue tempor ante mollis, sed placerat
                        diam porttitor. Vestibulum dignissim sem vel velit
                        eleifend, non pellentesque quam convallis. Pellentesque
                        est dolor, dignissim ac tortor tristique, hendrerit
                        iaculis metus. Praesent pulvinar quam eu leo consectetur
                        faucibus. Vestibulum purus diam, gravida sagittis
                        feugiat sit amet, tincidunt in ligula. Sed semper
                        vestibulum magna. Lorem ipsum dolor sit amet,
                        consectetur adipiscing elit. Suspendisse tortor nibh,
                        convallis sed purus nec, auctor venenatis nisl.
                        Suspendisse potenti.
                    </p>

                    <p>
                        Nullam sagittis nulla in diam finibus, sed pharetra
                        velit vestibulum. Suspendisse euismod in urna eu
                        posuere. Etiam blandit nunc arcu, et consectetur orci
                        blandit a. Aliquam condimentum pharetra quam at
                        ultricies. Nunc vel lacinia lorem. Nullam tincidunt sed
                        purus eu placerat. Donec id dictum erat. Etiam enim ex,
                        dapibus et tortor id, posuere pretium est. Maecenas
                        fringilla ipsum vitae neque elementum, at eleifend ante
                        sollicitudin. Donec viverra augue dolor, a venenatis
                        tellus consectetur sit amet.
                    </p>
                </div>

                <!-- / CONTENT -->
            </div>
            <!-- / CONTENT -->
        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>
