<div class="dialog-container">
    <h1 mat-dialog-title>Estimation du bien</h1>

    <div *ngIf="!showElement"  class="circle-progress-container" fxLayout="row" fxLayoutAlign="center center">

        <circle-progress
            [percent]="100"
            [radius]="100"
            [outerStrokeWidth]="16"
            [innerStrokeWidth]="8"
            [outerStrokeColor]="'#78C000'"
            [innerStrokeColor]="'#C7E596'"
            [animation]="true"
            [animationDuration]="4000"
        ></circle-progress>



    </div>

    <div *ngIf="showElement" style="height:400x; width:400px;">

        <div class="estimate-dialog-section">

            <div class="estimate-dialog-container">
               

                <div class="estimate-price-card estimate-price-card-moy">
                    <div class="estimate-title">Prix estimé</div>
                    <div class="estimate-price">{{prix}}€</div>
                    
                </div>

            </div>

        </div>


       

        <div class="valid-price-content">
            <label class="radio-label radio-label-price">Validez le prix</label>

            <input matInput class="valid-price-input" [value]="prix" [(ngModel)]="prix">
        </div>


        <div fxLayout="row" fxLayoutAlign="end center">
            <button mat-raised-button="mat-raised-button" type="button"
                    class="save-btn"
                    (click)="dialogRef.close(prix)"

            >Enregistrer</button>
        </div>

    </div>
</div>