<button class="close" type="button" [mat-dialog-close]="true">
    <mat-icon>close</mat-icon>
</button>

<mat-dialog-content class="mat-typography">
    <div id="login" class="inner-scroll" fxLayout="row" fxLayoutAlign="start">
        <div id="login-intro" fxFlex fxHide fxShow.gt-xs>
            <div
                class="logo"
                [@animate]="{ value: '*', params: { scale: '0.2' } }"
            >
                <img src="../../../../assets/images/logos/oikos_logo.png" />
            </div>

            <div
                class="title"
                [@animate]="{
                    value: '*',
                    params: { delay: '50ms', y: '25px' }
                }"
            >
                Bienvenue Chez Oikos
            </div>

            <div
                class="description"
                [@animate]="{
                    value: '*',
                    params: { delay: '100ms', y: '25px' }
                }"
            >
                Oikos est une plateforme qui facilite la phenomene de vente et
                achat pour les biens
            </div>
        </div>

        <div
            id="login-form-wrapper"
            [@animate]="{ value: '*', params: { delay: '300ms', x: '100%' } }"
        >
            <div id="login-form">
                <div class="logo" fxHide.gt-xs>
                    <img src="../../../../assets/images/logos/oikos_logo.png" />
                </div>

                <div class="title">CONNECTEZ-VOUS</div>

                <form
                    name="loginForm"
                    [formGroup]="loginForm"
                    (ngSubmit)="onSubmit()"
                    novalidate
                >
                    <mat-form-field appearance="outline">
                        <mat-label>Email</mat-label>
                        <input matInput formControlName="email" />
                        <mat-icon matSuffix class="secondary-text"
                            >mail</mat-icon
                        >
                        <mat-error
                            *ngIf="loginForm.get('email').hasError('required')"
                        >
                            Email requis
                        </mat-error>
                        <mat-error
                            *ngIf="
                                !loginForm.get('email').hasError('required') &&
                                loginForm.get('email').hasError('email')
                            "
                        >
                            S'il vous plaît, mettez une adresse email valide
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>Mot de passe</mat-label>
                        <input
                            matInput
                            type="password"
                            formControlName="password"
                        />
                        <mat-icon matSuffix class="secondary-text"
                            >vpn_key</mat-icon
                        >
                        <mat-error> Mot de passe requis </mat-error>
                    </mat-form-field>

                    <div
                        class="remember-forgot-password"
                        fxLayout="row"
                        fxLayout.xs="column"
                        fxLayoutAlign="space-between center"
                    >
                        <mat-checkbox
                            class="remember-me"
                            aria-label="Remember Me"
                        >
                            Souviens de moi
                        </mat-checkbox>

                        <a class="forgot-password"> Mot de passe oublié? </a>
                    </div>

                    <button
                        mat-raised-button
                        color="accent"
                        class="submit-button"
                        aria-label="LOGIN"
                        [disabled]="loginForm.invalid"
                    >
                        CONNEXION
                    </button>
                </form>

                <div class="separator">
                    <span class="text">OU</span>
                </div>

                <div fxLayout="column" fxLayoutAlign="start center">
                    <button mat-raised-button class="google">
                        Connectez-vous avec Google
                    </button>

                    <button mat-raised-button class="facebook">
                        Se connecter avec Facebook
                    </button>
                </div>

                <div
                    class="register"
                    fxLayout="column"
                    fxLayoutAlign="center center"
                >
                    <span class="text">Vous n'avez pas de compte?</span>
                    <a class="link" (click)="openDialogRegister()"
                        >Créer un compte</a
                    >
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>
<!-- 
<mat-dialog-content class="mat-typography">
    <div id="login-form">
        <div class="title">CONNECTEZ-VOUS</div>
        <form
            name="loginForm"
            [formGroup]="loginForm"
            novalidate
            (ngSubmit)="onSubmit()"
        >
            <mat-label *ngIf="failedLogin" style="color: red"
                >Vous avez entré un nom d'utilisateur ou un mot de passe
                invalide
                <hr />
            </mat-label>

            <mat-form-field appearance="outline">
                <mat-label>Email</mat-label>
                <input matInput formControlName="email" />
                <mat-icon matSuffix class="secondary-text">mail</mat-icon>
                <mat-error *ngIf="loginForm.get('email').hasError('required')">
                    Email requis
                </mat-error>
                <mat-error
                    *ngIf="
                        !loginForm.get('email').hasError('required') &&
                        loginForm.get('email').hasError('email')
                    "
                >
                    S'il vous plaît, mettez une adresse email valide
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Mot de passe</mat-label>
                <input matInput type="password" formControlName="password" />
                <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                <mat-error> Mot de passe requis </mat-error>
            </mat-form-field>

            <div class="remember-forgot-password">
                <mat-checkbox class="remember-me" aria-label="Remember Me">
                    Souviens de moi
                </mat-checkbox>

                <a class="forgot-password"> Mot de passe oublié? </a>
            </div>

            <button
                mat-raised-button
                color="accent"
                class="submit-button"
                aria-label="LOGIN"
                [disabled]="loginForm.invalid"
            >
                CONNEXION
            </button>
        </form>
        <div class="bloc-btn">
            <p>
                vous n'avez pas de compte?
                <a class="link" (click)="openDialogRegister()">
                    <span>Inscrivez-vous</span></a
                >
            </p>

            <br />

            <div class="separator">
                <span class="text">OU</span>
            </div>

            <button mat-raised-button class="google">
                Connectez-vous avec Google
            </button>

            <button mat-raised-button class="facebook">
                Se connecter avec Facebook
            </button>
        </div>
    </div>
</mat-dialog-content>
-->
