import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {Service} from '../../models/service.model';
import {CreditCardComponent} from '../credit-card/credit-card.component';


@Component({
    selector: 'conditions-dialog',
    templateUrl: './conditions-dialog.component.html',
    styleUrls: ['./conditions-dialog.component.scss'],
})
export class ConditionsDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<CreditCardComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Service
    ) {}

    onNoClick(): void {
        this.dialogRef.close();
    }
}
