import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatGridListModule } from "@angular/material/grid-list";

import { RegisterComponent } from "./register/register.component";
import { LoginComponent } from "./login/login.component";

import { AuthService } from "./services/auth.service";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FuseSharedModule } from "@fuse/shared.module";

@NgModule({
    declarations: [LoginComponent, RegisterComponent],
    imports: [
        CommonModule,
        MatDialogModule,
        MatButtonModule,
        FormsModule,
        ReactiveFormsModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatGridListModule,
        FlexLayoutModule,
        FuseSharedModule,
    ],

    exports: [LoginComponent, RegisterComponent],
    providers: [AuthService],
})
export class AuthModule {}
