import {
    Component,
    ViewEncapsulation,
    OnInit,
    AfterViewInit,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { PropertiesService } from "../../../services/properties.service";
import { FormControl, FormGroup } from "@angular/forms";
import { fuseAnimations } from "@fuse/animations";
import { FuseConfigService } from "@fuse/services/config.service";

@Component({
    selector: "bien-details",
    templateUrl: "./property-details.component.html",
    styleUrls: ["./property-details.component.scss"],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class PropertyDetailsComponent implements OnInit, AfterViewInit {
    types = ["Appartement", "Maison", "Villa", "Espace commercial"];
    idBien;
    bien;
    form: FormGroup;
    titleIsActive = false;

    constructor(
        private route: ActivatedRoute,
        private biensService: PropertiesService,
        private _fuseConfigService: FuseConfigService
    ) {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true,
                },
                toolbar: {
                    hidden: true,
                },
                footer: {
                    hidden: true,
                },
                sidepanel: {
                    hidden: true,
                },
            },
        };
    }

    ngOnInit(): void {
        this.idBien = this.route.snapshot.paramMap.get("id");
        this.biensService.getBienById(this.idBien).subscribe((data) => {
            this.bien = data;
        });
    }

    updateBien = () => {
        this.bien = Object.assign(this.bien, this.form.value);

        console.log(this.bien);

        this.biensService
            .editBien(this.bien, this.bien.id)
            .subscribe((data) => data);
    };

    toggleForms = () => {
        this.titleIsActive = !this.titleIsActive;
    };

    ngAfterViewInit(): void {
        this.form = new FormGroup({
            title: new FormControl(this.bien.title),
            description: new FormControl(this.bien.description),
            type: new FormControl(this.bien.type),
            address: new FormControl(this.bien.address),
            surface: new FormControl(this.bien.surface),
            rooms: new FormControl(this.bien.rooms),
            baths: new FormControl(this.bien.baths),
            yearBuilt: new FormControl(this.bien.yearBuilt),
            price: new FormControl(this.bien.price),
            status: new FormControl(this.bien.status),
        });
    }
}
