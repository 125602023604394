import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { MatButtonModule } from "@angular/material/button";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ColorPickerModule } from "ngx-color-picker";
import {
    CalendarModule as AngularCalendarModule,
    DateAdapter,
} from "angular-calendar";
import { adapterFactory } from "angular-calendar/date-adapters/date-fns";

import { FuseSharedModule } from "@fuse/shared.module";
import { FuseConfirmDialogModule } from "@fuse/components";

import { CalendarComponent } from "./calendar.component";
import { CalendarService } from "./calendar.service";
import { CalendarEventFormDialogComponent } from "./event-form/event-form.component";

import { FlatpickrModule } from "angularx-flatpickr";

import { AddVisiteComponent } from "./add-visite/add-visite.component";

const routes: Routes = [
    {
        path: "calendar",
        component: CalendarComponent,
        children: [],
        resolve: {
            chat: CalendarService,
        },
    },
];

@NgModule({
    declarations: [
        CalendarComponent,
        CalendarEventFormDialogComponent,
        AddVisiteComponent,
    ],
    imports: [
        RouterModule.forChild(routes),

        MatButtonModule,
        MatDatepickerModule,
        MatDialogModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatSlideToggleModule,
        MatToolbarModule,
        MatTooltipModule,

        FlatpickrModule.forRoot(),
        AngularCalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory,
        }),
        ColorPickerModule,

        FuseSharedModule,
        FuseConfirmDialogModule,
    ],
    providers: [CalendarService],
    entryComponents: [CalendarEventFormDialogComponent],
})
export class CalendarModule {}
