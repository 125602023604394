import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { FlexLayoutModule } from '@angular/flex-layout';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FuseSharedModule } from '@fuse/shared.module';
import { FuseWidgetModule } from '@fuse/components/widget/widget.module';

import { PropertiesService } from './services/properties.service';
import { MatDialogModule } from '@angular/material/dialog';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { UserService } from './services/user.service';
import { PropertyListComponent } from './containers/owner/property-list/property-list.component';
import { PropertyAddComponent } from './containers/owner/property-add/property-add.component';
import { PropertyDetailsComponent } from './containers/owner/property-details/property-details.component';
import { VisitorHomeComponent } from './containers/visitor/visitor-home/visitor-home.component';
import { VisitorPropertySearchComponent } from './containers/visitor/visitor-property-search/visitor-property-search.component';
import { VisitorPropertyDetailsComponent } from './containers/visitor/visitor-property-details/visitor-property-details.component';
import { MatStepperModule } from '@angular/material/stepper';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { LoginComponent } from '../auth/login/login.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { PropertyEstimateComponent } from './containers/owner/property-estimate/property-estimate.component';
import { PropertyEstimateDialogComponent } from './components/property-estimate-dialog/property-estimate-dialog.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { ServiceDialogComponent } from './components/service-dialog/service-dialog.component';
import { AuthGuard } from 'app/middlewares/auth.guard';
import {OrderConfirmationDialogComponent} from './components/order-confirmation-dialog/order-confirmation-dialog.component';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';


const routes: Routes = [
    {
        path: 'owner/properties',
        component: PropertyListComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'owner/property/new',
        component: PropertyAddComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'owner/property/:id',
        component: PropertyDetailsComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'visitor/home',
        component: VisitorHomeComponent,
    },
    {
        path: 'visitor/property/:id',
        component: VisitorPropertyDetailsComponent,
    },
    {
        path: 'visitor/properties/search',
        component: VisitorPropertySearchComponent,
    },
    {
        path: 'property/estimate',
        component: PropertyEstimateComponent,
    },
];

@NgModule({
    declarations: [
        // LoginComponent,
        PropertyListComponent,
        PropertyAddComponent,
        PropertyDetailsComponent,
        PropertyEstimateComponent,
        VisitorHomeComponent,
        VisitorPropertySearchComponent,
        VisitorPropertyDetailsComponent,
        PropertyEstimateDialogComponent,
        ServiceDialogComponent,
        OrderConfirmationDialogComponent
    ],
    imports: [
        BrowserModule,
        RouterModule.forChild(routes),
        GooglePlaceModule,

        MatButtonModule,
        MatChipsModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatRippleModule,
        MatSelectModule,
        MatSortModule,
        MatSnackBarModule,
        MatTableModule,
        MatTabsModule,
        MatDividerModule,
        MatMenuModule,

        BrowserAnimationsModule,
        MatToolbarModule,
        MatSidenavModule,
        MatListModule,
        FlexLayoutModule,

        FormsModule,
        ReactiveFormsModule,

        FuseSharedModule,
        FuseWidgetModule,

        MatDialogModule,
        MatDatepickerModule,
        MatCheckboxModule,
        MatStepperModule,
        IvyCarouselModule,
        MatSlideToggleModule,
        MatRadioModule,
        
        MatProgressSpinnerModule,
     

        NgCircleProgressModule.forRoot({
            // set defaults here
            radius: 100,
            outerStrokeWidth: 16,
            innerStrokeWidth: 8,
            outerStrokeColor: '#78C000',
            innerStrokeColor: '#C7E596',
            animationDuration: 300,
        }),
    ],
    // exports: [
    //     LoginComponent
    // ],
    providers: [
        PropertiesService,
        UserService,
        {
            provide: STEPPER_GLOBAL_OPTIONS,
            useValue: { displayDefaultIndicatorType: false }
        }
    ],
})
export class PropertiesModule {}
