import {
    Component,
    ElementRef,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";

import { PropertiesService } from "../../../services/properties.service";
import { fuseAnimations } from "@fuse/animations";
import { FuseConfigService } from "@fuse/services/config.service";

@Component({
    selector: "properties",
    templateUrl: "./property-list.component.html",
    styleUrls: ["./property-list.component.scss"],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class PropertyListComponent implements OnInit {
    displayedColumns: string[] = ["title", "type", "address"];
    properties = [];

    constructor(
        private propertiesService: PropertiesService,
        private _fuseConfigService: FuseConfigService
    ) {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true,
                },
                toolbar: {
                    hidden: true,
                },
                footer: {
                    hidden: true,
                },
                sidepanel: {
                    hidden: true,
                },
            },
        };
    }

    ngOnInit(): void {
        console.log(this.propertiesService.getBiens())
        this.propertiesService.getBiens().subscribe((data) => {
            
        });
    }
}
