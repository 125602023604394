import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule, Routes } from "@angular/router";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { TranslateModule } from "@ngx-translate/core";
import { MatMenuModule } from "@angular/material/menu";
import {MatRadioModule} from '@angular/material/radio';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from "@angular/material/form-field";


import { FuseModule } from "@fuse/fuse.module";
import { FuseSharedModule } from "@fuse/shared.module";
import {
    FuseProgressBarModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,
} from "@fuse/components";

import { fuseConfig } from "app/fuse-config";

import { AppComponent } from "app/app.component";
import { LayoutModule } from "app/layout/layout.module";
import { PropertiesModule } from "./main/properties/properties.module";
import { VisitesModule } from "./main/visites/visites.module";
import { ServiceOrdersModule } from "./main/service-orders/service-orders.module";
import { AuthModule } from "./main/auth/auth.module";
import { NavigationService } from "./middlewares/navigation.service";
import { AuthGuard } from "./middlewares/auth.guard";


export const ROUTES: Routes = [
    {
        path: "**",
        redirectTo: "/visitor/home",
    },

    {
        path: "property",
        loadChildren: "./main/properties/properties.module#PropertiesModule",
    },
    {
        path: "services",
        loadChildren:
            "./main/service-orders/service-orders.module#ServiceOrdersModule",
    },
    {
        path: "visites",
        loadChildren: "./main/visites/visites.module#VisitesModule",
    },
];

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        GooglePlaceModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(ROUTES, { relativeLinkResolution: "legacy" }),

        TranslateModule.forRoot(),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatRadioModule,
        

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,

        PropertiesModule,
        ServiceOrdersModule,
        VisitesModule,
        AuthModule,
        MatFormFieldModule,
        MatInputModule
    ],
   

    bootstrap: [AppComponent],
    providers: [NavigationService, AuthGuard],
    exports: [GooglePlaceModule],
})
export class AppModule {}
