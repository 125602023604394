<div style="width: 100%">
    <div class="page-layout carded fullwidth inner-scroll">
        <!-- CENTER -->
        <div class="center">
            <!-- HEADER -->
            <div
                class="header"
                fxLayout="column"
                fxLayoutAlign="center center"
                fxLayout.gt-xs="row"
                fxLayoutAlign.gt-xs="space-between center"
            >
                <!-- APP TITLE -->
                <div
                    class="logo mb-24 mb-sm-0"
                    fxLayout="row"
                    fxLayoutAlign="start center"
                >
                    <mat-icon
                        class="logo-icon s-32 mr-16"
                        [@animate]="{
                            value: '*',
                            params: { delay: '50ms', scale: '0.2' }
                        }"
                    >
                        home
                    </mat-icon>
                    <span
                        class="logo-text h1 bold"
                        [@animate]="{
                            value: '*',
                            params: { delay: '100ms', x: '-25px' }
                        }"
                    >
                        Mes Biens
                    </span>
                </div>
                <!-- / APP TITLE -->

                <button
                    mat-raised-button
                    type="button"
                    color="primary"
                    [routerLink]="'/owner/property/new'"
                >
                    + Ajouter un bien
                </button>
            </div>
            <!-- / HEADER -->

            <div class="property-table-container">
                <table class="property-table">
                    <tr class="table-row-titles">
                        <td>Image</td>
                        <td>Information</td>
                        <td>Etat</td>
                        <td>Date publication</td>
                        <td>Actions</td>
                    </tr>
                    <tr
                        *ngFor="let property of properties"
                        class="table-row-properties"
                    >
                        <td [routerLink]="['/owner/property/', property.id]">
                            <img
                                class="property-img"
                                [src]="
                                    'assets/images/app-images/' +
                                    property?.photos[0]
                                "
                            />
                        </td>
                        <td
                            class="infos-td"
                            [routerLink]="['/owner/property/', property.id]"
                        >
                            <div class="infos-column">
                                <div class="property-title">
                                    {{ property?.title }}
                                </div>
                                <div class="property-type">
                                    {{ property?.type }}
                                </div>
                                <div class="property-price">
                                    {{ property?.price }}000 £
                                </div>
                                <div class="property-address">
                                    <mat-icon class="property-address"
                                        >location_on</mat-icon
                                    >
                                    {{ property?.address }}
                                </div>
                            </div>
                        </td>

                        <td class="property-pub-date">
                            <div class="property-status">
                                {{ property?.status }}
                            </div>
                        </td>
                        <td>05 Avril 2021</td>
                        <td>
                            <button mat-stroked-button color="primary">
                                <mat-icon class="actions-icon">edit</mat-icon>
                            </button>
                            <button mat-stroked-button color="primary">
                                <mat-icon class="actions-icon"
                                    >delete_forever</mat-icon
                                >
                            </button>
                        </td>
                    </tr>
                </table>
            </div>

            <!--
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">




                <ng-container matColumnDef="title">
                    <th mat-header-cell *matHeaderCellDef> Titre</th>
                    <td mat-cell *matCellDef="let element"> {{element.title}} </td>
                </ng-container>


                <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef> Type</th>
                    <td mat-cell *matCellDef="let element"> {{element.type}} </td>
                </ng-container>


                <ng-container matColumnDef="address">
                    <th mat-header-cell *matHeaderCellDef> Adresse</th>
                    <td mat-cell *matCellDef="let element"> {{element.address}} </td>
                </ng-container>





                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr class="example-element-row"
                    mat-row *matRowDef="let row; columns: displayedColumns;"
                    [routerLink]="'/owner/property/'+row.id"></tr>


            </table>

-->
        </div>
    </div>
</div>
