import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { AuthService } from 'app/main/auth/services/auth.service';
import { log } from 'console';


@Component({
    selector: 'property-estimate-dialog',
    templateUrl: './property-estimate-dialog.component.html',
    styleUrls: ['./property-estimate-dialog.component.scss'],
})
export class PropertyEstimateDialogComponent implements OnInit {
    cd: any;
    prix : string="" ;
    
   

    constructor(
        private authService: AuthService,
        
        
        public dialogRef: MatDialogRef<PropertyEstimateDialogComponent>,
        
        
        @Inject(MAT_DIALOG_DATA) public data) {
            setTimeout(() => {
                this.showElement = true;
                
                console.log(this.authService.get_value().then(response=>
                    {
                    this.prix =response.data;
                    console.log(" this.prix", this.prix);
                    })
                    )
                
            }, 20000);
            
                
                 
        }
       

    value = 50;
    priceMin = '138 420'
    priceMoy = this.prix;
    priceMax = '192 600' 
    priceSelected = '';
    showElement = false;
    price = '';
  


  

    ngOnInit(): void {

       
    }

    savePrice = () => {
        console.log(this.data.price);
      
        // this.dialogRef.close();
    }
   




}
