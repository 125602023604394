<div class="page-layout carded fullwidth">




    <div class="add-bien-container">
        <div class="add-bien-title">
            <h1>Ajouter un nouveau bien </h1>
        </div>
        <div class="add-bien-content">
            <mat-horizontal-stepper class="mat-elevation-z4" [linear]="true">

                <mat-step [stepControl]="horizontalStepperStep1">

                    <form fxLayout="column" [formGroup]="horizontalStepperStep1">

                        <ng-template matStepLabel>Information sur le bien</ng-template>

                        <div fxFlex="1 0 auto" fxLayout="column">


                            <mat-form-field appearance="outline" fxFlex="100">
                                <mat-label>Type</mat-label>
                                <mat-select formControlName="type" name="type">
                                    <mat-option *ngFor="let type of types" [value]="type">{{type}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="100">
                                <mat-label>Adresse</mat-label>
                                <input matInput formControlName="address" name="address">

                            </mat-form-field>
                            <div fxLayout="row">

                                <mat-form-field appearance="outline" fxFlex="50" style="margin-right:10px">
                                    <mat-label>Nombre de pièces</mat-label>
                                    <input type="number" matInput formControlName="nbrPieces" name="nbrPieces"
                                           (click)="showComment()">

                                </mat-form-field>

                                <mat-form-field appearance="outline" fxFlex="50">
                                    <mat-label>Nombre de chambre</mat-label>
                                    <input type="number" matInput formControlName="nbrChambre" name="nbrChambre">

                                </mat-form-field>
                            </div>

                            <div class="comment-info" *ngIf="isShowed">
                                <div>
                                    <img class="info-icon" [src]="'assets/images/app-images/svg/fi-rr-info.svg'">

                                    La cuisine , la salle de bain et les toilettes ne sont pas à prendre en compte
                                </div>

                                <div class="close-icon" (click)="hideComment()"> x</div>

                            </div>

                            <div class="slide-toggle-section">
                                <div class="slide-toggle-content">
                                    <mat-slide-toggle>Sallon</mat-slide-toggle>
                                    <mat-slide-toggle>Cuisine</mat-slide-toggle>
                                    <mat-slide-toggle>Terasse</mat-slide-toggle>
                                    <mat-slide-toggle>Garage</mat-slide-toggle>

                                </div>
                                <div class="slide-toggle-content">
                                    <mat-slide-toggle>Balcon</mat-slide-toggle>
                                    <mat-slide-toggle>Jardin</mat-slide-toggle>
                                    <mat-slide-toggle>Cave</mat-slide-toggle>
                                    <mat-slide-toggle>Piscine</mat-slide-toggle>
                                </div>
                            </div>


                            <mat-form-field appearance="outline" fxFlex="100">
                                <mat-label>Surface en m²</mat-label>
                                <input type="number" matInput name="surface" formControlName="surface">
                            </mat-form-field>


                            <mat-form-field appearance="outline" fxFlex="100">
                                <mat-label>Année de construction</mat-label>
                                <input type="number" matInput formControlName="yearBuilt" name="yearBuild">
                            </mat-form-field>

                            <div fxLayout="row" class="property-radio-section">
                                <label id="example-radio-group-label" class="radio-label">Des travaux à prévoir?</label>
                                <mat-radio-group
                                    aria-labelledby="example-radio-group-label"
                                    class="example-radio-group">
                                    <mat-radio-button class="property-radio-button" [value]="'oui'"> Oui
                                    </mat-radio-button>
                                    <mat-radio-button class="property-radio-button" [value]="'non'"> Non
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>

                            <mat-form-field appearance="outline" fxFlex="100">
                                <mat-label>Surface en m² de terrain ( Bâti de la maison inclus )</mat-label>
                                <input type="number" matInput name="surfaceTerrain" formControlName="surfaceTerrain">
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="100">
                                <mat-label>Surface en m² de séjour</mat-label>
                                <input type="number" matInput name="surfaceSejour" formControlName="surfaceSejour">
                            </mat-form-field>

                            <div class="properties-fields-section">

                                <div fxLayout="row" class="property-dep-radio-section property-field">
                                    <label id="example-radio-group-label2" class="radio-label">Avez-vous une dépendance
                                        ?</label>
                                    <mat-radio-group
                                        aria-labelledby="example-radio-group-label2"
                                        class="example-radio-group">
                                        <mat-radio-button class="property-radio-button" [value]="'oui'"> Oui
                                        </mat-radio-button>
                                        <mat-radio-button class="property-radio-button" [value]="'non'"> Non
                                        </mat-radio-button>
                                    </mat-radio-group>
                                </div>


                                <div class="exp-slide-toggle-section property-field">
                                    <div class="radio-label">Exportation de votre Bien</div>
                                    <div class="exp-slide-toggle-content">
                                        <mat-slide-toggle>Nord</mat-slide-toggle>
                                        <mat-slide-toggle>Sud</mat-slide-toggle>
                                        <mat-slide-toggle>Est</mat-slide-toggle>
                                        <mat-slide-toggle>Ouest</mat-slide-toggle>

                                    </div>

                                </div>


                                <div class="property-field">
                                    <div fxLayout="row" class="property-dep-radio-section">
                                        <label id="example-radio-group-label3" class="radio-label">La vue de votre
                                            Bien</label>
                                        <mat-radio-group
                                            aria-labelledby="example-radio-group-label3"
                                            class="example-radio-group">
                                            <mat-radio-button class="property-radio-button" [value]="'Vis-à-vis'">
                                                Vis-à-vis
                                            </mat-radio-button>
                                            <mat-radio-button class="property-radio-button" [value]="'Dégagée'">
                                                Dégagée
                                            </mat-radio-button>
                                            <mat-radio-button class="property-radio-button" [value]="'Exceptionnelle'">
                                                Exceptionnelle
                                            </mat-radio-button>

                                        </mat-radio-group>

                                    </div>


                                    <div class="comment-info comment-info-showed" *ngIf="isShowed">
                                        <div fxLayout="row">
                                            <img class="info-icon"
                                                 [src]="'assets/images/app-images/svg/fi-rr-info.svg'">

                                            <div>
                                                Une vue peut être qualifiée d’exceptionnelle
                                                lorsque le bien donne sur un monument ou un
                                                élément naturel notable comme la mer, la
                                                montagne etc.
                                            </div>

                                        </div>
                                        <div class="close-icon" (click)="hideComment()"> x</div>

                                    </div>
                                </div>


                                <div class="property-field">
                                    <div fxLayout="row" class="property-dep-radio-section">
                                        <label id="example-radio-group-label4" class="radio-label">Standing de votre
                                            Bien
                                        </label>
                                        <mat-radio-group
                                            aria-labelledby="example-radio-group-label4"
                                            class="example-radio-group">
                                            <mat-radio-button class="property-radio-button" [value]="'Moyen'"> Moyen
                                            </mat-radio-button>
                                            <mat-radio-button class="property-radio-button" [value]="'Standard'">
                                                Standard
                                            </mat-radio-button>
                                            <mat-radio-button class="property-radio-button" [value]="'Exceptionnel'">
                                                Exceptionnel
                                            </mat-radio-button>

                                        </mat-radio-group>

                                    </div>


                                    <div class="comment-info comment-info-showed" *ngIf="isShowed">
                                        <div fxLayout="row">
                                            <img class="info-icon"
                                                 [src]="'assets/images/app-images/svg/fi-rr-info.svg'">

                                            <div>
                                                Nous entendons par ‘Standing’, le niveau de
                                                l’habitation en matière de qualité de construction,
                                                d’équipements et de confort.
                                            </div>

                                        </div>
                                        <div class="close-icon" (click)="hideComment()"> x</div>
                                    </div>
                                </div>


                                <div fxLayout="row" class="property-dep-radio-section property-field">
                                    <label id="example-radio-group-label5" class="radio-label">Votre Bien est mitoyen
                                    </label>
                                    <mat-radio-group
                                        aria-labelledby="example-radio-group-label5"
                                        class="example-radio-group">
                                        <mat-radio-button class="property-radio-button" [value]="'Oui'"> Oui
                                        </mat-radio-button>
                                        <mat-radio-button class="property-radio-button" [value]="'Non'"> Non
                                        </mat-radio-button>

                                    </mat-radio-group>

                                </div>


                                <div  class="property-field">
                                    <div fxLayout="row" class="property-dep-radio-section">
                                        <label id="example-radio-group-label6" class="radio-label">Emplacement de votre
                                            Bien

                                        </label>
                                        <mat-radio-group
                                            aria-labelledby="example-radio-group-label6"
                                            class="example-radio-group">
                                            <mat-radio-button class="property-radio-button" [value]="'Bruyant'">
                                                Bruyant
                                            </mat-radio-button>
                                            <mat-radio-button class="property-radio-button" [value]="'Standard'">
                                                Standard
                                            </mat-radio-button>
                                            <mat-radio-button class="property-radio-button" [value]="'Calme'"> Calme
                                            </mat-radio-button>

                                        </mat-radio-group>

                                    </div>


                                    <div class="comment-info comment-info-showed" *ngIf="isShowed">
                                        <div fxLayout="row">
                                            <img class="info-icon"
                                                 [src]="'assets/images/app-images/svg/fi-rr-info.svg'">

                                            <div>
                                                Bruyant : proche d’artères à circulation dense,voies ferrées, aéroport
                                                etc.
                                                <br/>Standard : proche de rues animées, commerces ou restaurants etc.
                                                <br/>Calme : retiré ou donnant sur cour, en zone résidentielle etc.

                                            </div>

                                        </div>
                                        <div class="close-icon" (click)="hideComment()"> x</div>
                                    </div>
                                </div>


                            </div>


                        </div>


                        <div fxLayout="row" fxLayoutAlign="end center">
                            <button mat-raised-button matStepperNext type="button" color="accent">
                                Suivant
                            </button>


                        </div>

                    </form>

                </mat-step>

                <mat-step [stepControl]="horizontalStepperStep2">

                    <form fxLayout="column" [formGroup]="horizontalStepperStep2">

                        <ng-template matStepLabel>Caractéristiques techniques</ng-template>


                    </form>

                </mat-step>

                <mat-step [stepControl]="horizontalStepperStep3">

                    <form fxLayout="column" [formGroup]="horizontalStepperStep3">

                        <ng-template matStepLabel>Photos</ng-template>

                        <div fxFlex="1 0 auto" fxLayout="column">



                        </div>

                        <div fxLayout="row" fxLayoutAlign="end center">
                            <button class="mr-8" mat-raised-button matStepperPrevious type="button" color="accent">
                                Précédent
                            </button>
                            <button mat-raised-button matStepperNext type="button" color="accent">
                                Suivant
                            </button>
                        </div>

                    </form>

                </mat-step>


                <mat-step [stepControl]="horizontalStepperStep4">

                    <form fxLayout="column" [formGroup]="horizontalStepperStep4">

                        <ng-template matStepLabel>Estimation du bien</ng-template>

                        <div fxFlex="1 0 auto" fxLayout="column">



                        </div>

                        <div fxLayout="row" fxLayoutAlign="end center">
                            <button class="mr-8" mat-raised-button matStepperPrevious type="button" color="accent">
                                Précédent
                            </button>
                            <button mat-raised-button type="button" color="primary" (click)="addBien()"
                                    [routerLink]="'/owner/properties'">
                                Terminé
                            </button>
                        </div>

                    </form>

                </mat-step>


            </mat-horizontal-stepper>
        </div>


    </div>

</div>
