import {Component, Inject} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Service } from '../../models/service.model';
import {ServiceOrdersService} from '../../services/service-orders.service';


@Component({
    selector   : 'credit-card',
    templateUrl: './credit-card.component.html',
    styleUrls  : ['./credit-card.component.scss']
})
export class CreditCardComponent {

    service: Service
    constructor(
        private route: ActivatedRoute,
        private serv: ServiceOrdersService,
        public dialog: MatDialog) { }


    ngOnInit = () => {
        const id = this.route.snapshot.paramMap.get('id');
        this.serv.getService(id)
            .subscribe(data => {
                this.service = data;
            });

    }

    newCommand = () => {

       /* this.commandesService
            .addCommand({
                service_id: this.service.id,
                owner_id: 3,
                approuved: false,
                affected: false,
                company_id: null,
                prestator_id: null,
                created_at: Date.now(),
            })
            .then(() => {});*/
    }
}
