import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';

import { FuseSidebarModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';

import { ContentModule } from 'app/layout/components/content/content.module';
import { FooterModule } from 'app/layout/components/footer/footer.module';
import { NavbarModule } from 'app/layout/components/navbar/navbar.module';
import { QuickPanelModule } from 'app/layout/components/quick-panel/quick-panel.module';
import { ToolbarModule } from 'app/layout/components/toolbar/toolbar.module';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatListModule } from '@angular/material/list';
import { VerticalLayout1Component } from 'app/layout/vertical/layout-1/layout-1.component';
import {MatMenuModule} from '@angular/material/menu';
import {ShoppingCartItemsComponent} from '../../../main/service-orders/components/shopping-cart-items/shopping-cart-items.component';
import {ShoppingCartItemsModule} from '../../../main/service-orders/components/shopping-cart-items/shopping-cart-items.module';

@NgModule({
    declarations: [VerticalLayout1Component],
    imports: [
        RouterModule,

        FuseSharedModule,
        FuseSidebarModule,

        MatButtonModule,
        MatIconModule,
        MatToolbarModule,
        MatSidenavModule,
        FlexLayoutModule,
        MatListModule,

        ContentModule,
        FooterModule,
        NavbarModule,
        QuickPanelModule,
        ToolbarModule,
        MatMenuModule,

        ShoppingCartItemsModule
    ],
    exports: [VerticalLayout1Component],
})
export class VerticalLayout1Module {}
