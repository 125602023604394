import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { CommonModule } from "@angular/common";

const routes: Routes = [];

@NgModule({
    declarations: [
        // ShoppingCartItemsComponent,
    ],
    imports: [
        RouterModule.forChild(routes),
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        CommonModule,
    ],
    entryComponents: [],
    exports: [
        // ShoppingCartItemsComponent
    ],
    providers: [],
})
export class ShoppingCartItemsModule {}
