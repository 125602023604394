<div class="card" [routerLink]="['/owner/service/', service.id]">
    <div class="card-preview">
        <div class="fuse-card" style="height: 400px">
            <img style="height: 200px" src="{{ service.fileToUpload }}" />

            <div class="p-16">
                <div class="h1">{{ service.name }}</div>
                <!-- <div class="h4 secondary-text">Free on Thursdays</div> -->
            </div>

            <div class="p-16 pt-0 line-height-1.75">
                {{ service.subtitle }}
            </div>
        </div>
    </div>
</div>
