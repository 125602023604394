import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { fuseAnimations } from "@fuse/animations";
import { NavigationService } from "app/middlewares/navigation.service";
import { RegisterComponent } from "../register/register.component";
import { AuthService } from "../services/auth.service";

@Component({
    selector: "login-2",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.scss"],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    failedLogin: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<LoginComponent>,
        public router: Router,
        private _formBuilder: FormBuilder,
        private authService: AuthService,
        private navigationService: NavigationService,
        public dialog: MatDialog
    ) {}
    ngOnInit(): void {
        this.loginForm = this._formBuilder.group({
            email: ["", [Validators.required, Validators.email]],
            password: ["", Validators.required],
        });
    }

    onSubmit(): void {
        this.authService
            .login({
                email: this.loginForm.controls.email.value,
                password: this.loginForm.controls.password.value,
            })
            .then((resp) => {
                if (resp.data.length > 0) {
                    localStorage.setItem("user", JSON.stringify(resp.data[0]));
                    console.log("user", JSON.stringify(resp.data[0]));
                    localStorage.setItem("is_logged_in", JSON.stringify(true));
                    console.log("is_logged_in", JSON.stringify(true));
                    this.navigationService.redirectSwitchAccount();
                    this.navigationService.changeNavVisibilitySwitchUser();
                    this.dialogRef.close();
                    console.log("data", resp.data);
                } else {
                    console.log("user undefined");
                    this.failedLogin = true;
                }
            });
    }
    openDialogRegister = () => {
        const dialogRef = this.dialog.open(RegisterComponent, {
            panelClass: "my-dialog-login",
        });

        dialogRef.afterClosed().subscribe((result) => {
            console.log(`Dialog Register result: ${result}`);
        });
        this.dialogRef.close();
    };
}
