<button class="close" type="button" [mat-dialog-close]="true">
    <mat-icon>close</mat-icon>
</button>
<mat-dialog-content class="mat-typography">
    <div id="register" fxLayout="row" fxLayoutAlign="start">
        <div id="register-intro" fxFlex fxHide fxShow.gt-xs>
            <div
                class="logo"
                [@animate]="{ value: '*', params: { scale: '0.2' } }"
            >
                <img src="../../../../assets/images/logos/oikos_logo.png" />
            </div>

            <div
                class="title"
                [@animate]="{
                    value: '*',
                    params: { delay: '50ms', y: '25px' }
                }"
            >
                Bienvenue Chez Oikos
            </div>

            <div
                class="description"
                [@animate]="{
                    value: '*',
                    params: { delay: '100ms', y: '25px' }
                }"
            >
                Oikos est une plateforme qui facilite la phenomene de vente et
                achat pour les biens
            </div>
        </div>

        <div
            id="register-form-wrapper"
            [@animate]="{ value: '*', params: { delay: '300ms', x: '100%' } }"
        >
            <div id="register-form">
                <div class="logo" fxHide.gt-xs>
                    <img src="../../../../assets/images/logos/oikos_logo.png" />
                </div>

                <div class="title">CRÉER UN COMPTE</div>

                <form
                    name="registerForm"
                    [formGroup]="registerForm"
                    (ngSubmit)="onSubmit()"
                    novalidate
                >
                    <mat-form-field appearance="outline">
                        <mat-label>Nom</mat-label>
                        <input matInput formControlName="name" />
                        <mat-icon matSuffix class="secondary-text"
                            >account_circle</mat-icon
                        >
                        <mat-error> Le nom est requis </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Prénom</mat-label>
                        <input matInput formControlName="prenom" />
                        <mat-icon matSuffix class="secondary-text"
                            >account_circle</mat-icon
                        >
                        <mat-error> Prénom est requis </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>Email</mat-label>
                        <input matInput formControlName="email" />
                        <mat-icon matSuffix class="secondary-text"
                            >mail</mat-icon
                        >
                        <mat-error
                            *ngIf="
                                registerForm.get('email').hasError('required')
                            "
                        >
                            Email est requis
                        </mat-error>
                        <mat-error
                            *ngIf="registerForm.get('email').hasError('email')"
                        >
                            S'il vous plaît, mettez une adresse email valide
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Numéro de téléphone</mat-label>
                        <input matInput formControlName="number" />
                        <mat-icon matSuffix class="secondary-text"
                            >number</mat-icon
                        >
                        <mat-error> Numéro de téléphone est requis </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Date de naissance</mat-label>
                        <input matInput formControlName="docdatetime"/>
                        <mat-icon matSuffix class="secondary-text"
                            >Date</mat-icon
                        >
                        <mat-error> Date de naissance est requis </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>Mot de passe</mat-label>
                        <input
                            matInput
                            type="password"
                            formControlName="password"
                        />
                        <mat-icon matSuffix class="secondary-text"
                            >vpn_key</mat-icon
                        >
                        <mat-error>Mot de passe requis</mat-error>
                    </mat-form-field>

                    

                   

                    <div
                        class="terms"
                        fxLayout="row"
                        fxLayoutAlign="center center"
                    >
                        <mat-checkbox name="terms" aria-label="Accept" required>
                            <span>J'accepte</span>
                        </mat-checkbox>
                        <a href="#">termes et conditions</a>
                    </div>

                    <button
                        mat-raised-button
                        color="accent"
                        class="submit-button"
                        aria-label="CREATE AN ACCOUNT"
                        [disabled]="registerForm.invalid"
                    >
                        CRÉER UN COMPTE
                    </button>
                </form>

                <div
                    class="register"
                    fxLayout="column"
                    fxLayoutAlign="center center"
                >
                    <span class="text">Vous avez déjà un compte?</span>
                    <a class="link" (click)="openDialogLogin()">Connexion</a>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>
