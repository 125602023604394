<div class="visiteur-biens-content">
    <div class="images-slider"></div>

    <div class="details-content" *ngIf="!titleIsActive">
        <div class="details-title">
            <h1 class="bold">{{ bien.title }}</h1>

            <button
                mat-raised-button
                type="button"
                color="accent"
                (click)="toggleForms()"
            >
                Modifier les informations
                <mat-icon class="icon" style="color: white"> edit</mat-icon>
            </button>
        </div>

        <div class="details-description-contact">
            <div class="details-desription-container">
                <h2 class="bold">Description</h2>

                <p class="text">{{ bien.description }}</p>

                <hr />

                <h2 class="bold">Details</h2>

                <div class="details-table-content">
                    <table>
                        <tr>
                            <td>Type:</td>
                            <td class="details-table-item bold">
                                {{ bien.type }}
                            </td>
                            <td>Chambre:</td>
                            <td class="bold">{{ bien.rooms }}</td>
                        </tr>
                        <tr>
                            <td>Surface:</td>
                            <td class="bold">{{ bien.surface }} m²</td>
                            <td>Salles de bains:</td>
                            <td class="bold">{{ bien.baths }}</td>
                        </tr>
                        <tr>
                            <td>Adresse:</td>
                            <td class="bold">
                                {{ bien.address }}, {{ bien.region }}
                            </td>
                            <td>Année de construction:</td>
                            <td class="bold">{{ bien.yearBuilt }}</td>
                        </tr>
                        <tr>
                            <td>Prix:</td>
                            <td class="bold">{{ bien.price }} 000 £</td>
                            <td>Statut:</td>
                            <td class="bold">{{ bien.status }}</td>
                        </tr>
                    </table>
                </div>

                <hr />

                <h2 class="bold">Photos</h2>

                <div class="details-photos">
                    <div
                        class="photo-card"
                        *ngFor="let photoName of bien.photos"
                    >
                        <img
                            class="bien-img"
                            [src]="'assets/images/app-images/' + photoName"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="details-content" *ngIf="titleIsActive">
        <form fxLayout="column" [formGroup]="form">
            <div class="details-title">
                <mat-form-field
                    style="width: 300px; font-size: 20px"
                    appearance="outline"
                >
                    <mat-label>titre</mat-label>

                    <input
                        matInput
                        value="{{ bien.title }}"
                        formControlName="title"
                    />
                </mat-form-field>

                <button
                    mat-raised-button
                    type="button"
                    color="accent"
                    (click)="toggleForms(); updateBien()"
                >
                    Enregistrer les modifications
                    <mat-icon class="icon" style="color: white"> save</mat-icon>
                </button>
            </div>

            <div class="details-description-contact">
                <div class="details-desription-container">
                    <h2 class="bold">Description</h2>

                    <mat-form-field
                        class="description-form"
                        appearance="outline"
                    >
                        <textarea
                            matInput
                            formControlName="description"
                            class="description-textarea"
                            value="{{ bien.description }}"
                        ></textarea>
                    </mat-form-field>

                    <h2 class="bold">Details</h2>

                    <div class="details-table-content">
                        <table>
                            <tr>
                                <td class="details-table-item bold">
                                    <mat-form-field appearance="outline">
                                        <mat-label>type</mat-label>
                                        <input
                                            matInput
                                            formControlName="type"
                                            value="{{ bien.type }}"
                                        />
                                    </mat-form-field>
                                </td>

                                <td class="bold">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Chambres</mat-label>
                                        <input
                                            matInput
                                            value=" {{ bien.rooms }}"
                                            formControlName="rooms"
                                        />
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <td class="bold">
                                    <mat-form-field appearance="outline">
                                        <mat-label>surface</mat-label>
                                        <input
                                            matInput
                                            value=" {{ bien.surface }}"
                                            formControlName="surface"
                                        />
                                    </mat-form-field>
                                </td>

                                <td class="bold">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Salles de bains</mat-label>
                                        <input
                                            matInput
                                            value="{{ bien.baths }}"
                                            formControlName="baths"
                                        />
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <td class="bold">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Adresse</mat-label>
                                        <input
                                            matInput
                                            value="{{ bien.address }} {{
                                                bien.region
                                            }}"
                                            formControlName="address"
                                        />
                                    </mat-form-field>
                                </td>

                                <td class="bold">
                                    <mat-form-field appearance="outline">
                                        <mat-label
                                            >Année de construction:</mat-label
                                        >
                                        <input
                                            matInput
                                            value="{{ bien.yearBuilt }}"
                                            formControlName="yearBuilt"
                                        />
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <td class="bold">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Prix</mat-label>
                                        <input
                                            matInput
                                            value="{{ bien.price }} 000 "
                                            formControlName="price"
                                        />
                                    </mat-form-field>
                                </td>

                                <td class="bold">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Statut</mat-label>
                                        <input
                                            matInput
                                            value=" {{ bien.status }}"
                                            formControlName="status"
                                        />
                                    </mat-form-field>
                                </td>
                            </tr>
                        </table>
                    </div>

                    <hr />

                    <h2 class="bold">Photos</h2>

                    <div class="details-photos">
                        <div
                            class="photo-card"
                            *ngFor="let photoName of bien.photos"
                        >
                            <img
                                class="bien-img"
                                [src]="
                                    'assets/images/property-list/' + photoName
                                "
                            />
                        </div>
                    </div>

                    <hr />

                    <h2 class="bold">Attachments</h2>

                    <div class="details-attachments"></div>
                </div>
            </div>
        </form>
    </div>
</div>
