<mat-toolbar>
    <div
        class="footer-list"
        fxLayout="row"
        fxLayoutAlign="center center"
        fxLayoutAlign.gt-xs="space-between center"
        fxFlex
        fxLayout.xs="column"
        fxLayout.sm="column"
    >
        <div fxLayout="row" fxLayoutAlign="start center">
            <a [routerLink]="'/visitor/home'"> Accueil </a>
            <a [routerLink]="'/visitor/properties/search'"> Immobiliers </a>
            <a [routerLink]="'/visitor/home'"> A propos </a>
            <a [routerLink]="'/visitor/home'"> Contact </a>
        </div>

        <div fxLayout="row" fxLayoutAlign="start center">
            <span>&copy; 2021 Oikos &bull; Neopolis Dev &bull; </span>
        </div>
    </div>
</mat-toolbar>
