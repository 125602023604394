<div class="page-layout carded fullwidth" style="min-height: 100vh">
    <div class="estimate-property-section">
        <div class="estimate-property-container" style="margin-bottom: 50px">
            <div class="estimate-property-title">
                <h1>Estimer mon bien</h1>
                <!-- <h1 *ngIf="isPriceShowed">Ajouter un bien</h1>-->
            </div>
            <div class="estimate-property-content">
                <div class="vertical-stepper-wrapper">
                    <mat-horizontal-stepper labelPosition="bottom" #stepper>
                       
                        <mat-step
                            [stepControl]="verticalStepperStep1"
                            state="state1"
                        >
                      
                      
                          
                            <form
                                
                                fxLayout="column"
                                [formGroup]="verticalStepperStep1"
                                style="white-space: nowrap;"
                               
                            >
                                <div
                                    fxFlex="1 0 auto"
                                    fxLayout="column"
                                    class="content-bloc"
                                >
                                <div class="center">

                                <div    class="row"   >
                                        <div  formControlName="type"
                                        name="type"> 
                                    <button  style="height: 150px;
                                    width: 300px;
                                    border: none;
                                    background-color: #E6E6E6;" 
                                     mat-raised-button
                                     matStepperNext
                                    class="button"
                                    color="black"
                                   (click)="choixbien($event)" id="Appartement" name="Appartement"  >
                                   <img src="assets/images/app-images/Appartement.png" width="50" height="50"/>
                                     <h2 style="color: black;"> Appartement</h2>
                                    </button>
                                    &nbsp;&nbsp;
                                  
                                   
                                   
                                   
                                    <button 
                                       style="height: 150px;
                                    width: 300px;
                                    border: none;
                                    background-color: #E6E6E6;"
                                    mat-raised-button
                                    matStepperNext
                                    color="black"
                                    (click)="choixbien($event)" id="Maison" name="Maison" >
                                    <img src="assets/images/app-images/House.png" width="50" height="50"/>
                                      <h2 style="color: black;">Maison</h2>
                                     </button>
                                </div>
                         
                                   
                                 


                             
                                </div>   </div>

                                      
                                         
                                       
                                     
                                        
                                       
                                                
                                       
                                         
                                    

                                     <div *ngIf="selectedOption ==='Maison'">
                                        
                                 
                               
                               
    
                                       
                
    
                                        <mat-step
                                        [stepControl]="verticalStepperStep1"
                                        state="state1"
                                    >
                                        <form 
                                            fxLayout="column"
                                            [formGroup]="verticalStepperStep1"
                                        >

                                            <div
                                                fxFlex="1 0 auto"
                                                fxLayout="column"
                                                class="content-bloc"
                                            >
                                            
                                            <mat-form-field
                                            appearance="outline"
                                            fxFlex="100"
                                        >
                                        <mat-label>Adresse</mat-label>
                                        <input ngx-google-places-autocomplete [options]='options' #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)"  matInput
                                        name="addresss" formControlName="addresss" />
                                        <mat-icon [ngStyle]="{color: iconColor}" matSuffix>room</mat-icon>
                                    </mat-form-field>
                                    <mat-form-field
                            appearance="outline"
                            fxFlex="100"
                        >
                            <mat-label
                                >Surface en m² de terrain ( Bâti de
                                la maison inclus )
                            </mat-label>
                            <input
                                type="number"
                                matInput
                                name="surfaceTerrain"
                                formControlName="surfaceTerrain" 
                            />
                        </mat-form-field>
                            <mat-form-field
                                appearance="outline"
                                fxFlex="100"
                            >
                                <mat-label>Surface en m²</mat-label>
                                <input
                                    type="number"
                                    matInput
                                    name="surfaceMaison"
                                    formControlName="surfaceMaison"
                                   
                                />
                            </mat-form-field>

                            
                                            <mat-form-field
                                            appearance="outline"
                                            fxFlex="50"
                                            style="margin-right: 10px"
                                        >
                                            <mat-label
                                                >Nombre de pièces principales
                                            </mat-label>
                                            <input
                                                type="number"
                                                matInput
                                                formControlName="nbPiecesMaison"
                                                name="nbPiecesMaison"
                                                (click)="showComment()"
                                            />
                                        </mat-form-field>
                                      
    
                                <div class="comment-info" *ngIf="isShowed">
                                    <div>
                                        <img
                                            class="info-icon"
                                            [src]="
                                                'assets/images/app-images/svg/fi-rr-info.svg'
                                            "
                                        />
    
                                        La cuisine , la salle de bain et les
                                        toilettes ne sont pas à prendre en
                                        compte
                                    </div>
    
                                    <div
                                        class="close-icon"
                                        (click)="hideComment()"
                                    >
                                        x
                                    </div>
                                    </div>
                                    <div class="slide-toggle-section">
                                        <div class="slide-toggle-content">
                                            
                                           
                                      
                                            <mat-slide-toggle [formControl]="caveMaison" formControlName="caveMaison"
                                                >Cave
                                            </mat-slide-toggle>
                                           
                                            <mat-slide-toggle [formControl]="Piscine" formControlName="Piscine"
                                                >Piscine
                                            </mat-slide-toggle>
                                           
                                        </div>
                                    </div>
                                    <div
                                    class="stepper-btns"
                                    *ngIf="!isPriceShowed"
                                >
                                    <button
                                        class="mr-8"
                                        mat-raised-button
                                        matStepperPrevious
                                        type="button"
                                        color="accent"
                                    >
                                        Précédent
                                    </button>

                                    <button
                                        mat-raised-button
                                        type="button"
                                        color="primary"
                                        (click)="openEstimateDialog()"
                                    >
                                        Estimer mon bien
                                    </button>
                                </div>
                                    <div>
                                        <div
                                            class="stepper-btns"
                                            *ngIf="isPriceShowed"
                                        >
                                            <button
                                                class="mr-8"
                                                mat-raised-button
                                                matStepperPrevious
                                                type="button"
                                                color="accent"
                                            >
                                                Précédent
                                            </button>
                                            <div>
                                                <button
                                                    class="mr-8"
                                                    mat-raised-button
                                                    type="button"
                                                    color="primary"
                                                    (click)="openEstimateDialog()"
                                                >
                                                    Estimer mon bien
                                                </button>
                                                <button
                                                    mat-raised-button
                                                    matStepperNext
                                                    type="button"
                                                    color="accent"
                                                    (click)="
                                                        openServiceDialog(
                                                            'Séance Photo',
                                                            1,
                                                            1200
                                                        )
                                                    "
                                                >
                                                    Suivant
                                                </button>
                                            </div>
                                        </div>
                                </div>
    
                               
                           
                        
                        

                           
                         </div>




                        </form></mat-step>
                                </div>








                                    </div>
                                   
                     <div *ngIf="selectedOption === 'Appartement'">

                        

                                    <mat-step
                                    [stepControl]="verticalStepperStep1"
                                    state="state1"
                                >
                                    <form
                                        fxLayout="column"
                                        [formGroup]="verticalStepperStep1"
                                    >
                                        <div
                                            fxFlex="1 0 auto"
                                            fxLayout="column"
                                            class="content-bloc"
                                        >
                                        <mat-form-field
                                        appearance="outline"
                                        fxFlex="100"
                                    >
                                    <mat-label>Adresse</mat-label>
                                    <input ngx-google-places-autocomplete [options]='options' #placesRef="ngx-places"(onAddressChange)="handleAddressChange($event)"
                                        matInput
                                        formControlName="address"
                                        name="address"
                                    />
                                    <mat-icon [ngStyle]="{color: iconColor}" matSuffix>room</mat-icon>
                                </mat-form-field>
                                        <mat-form-field
                                        appearance="outline"
                                        fxFlex="100"
                                    >
                                        <mat-label>Surface en m²</mat-label>
                                        <input
                                            type="number"
                                            matInput
                                            name="surface"
                                            formControlName="surface"
                                        />
                                    </mat-form-field>

                                   
                                        <mat-form-field
                                        appearance="outline"
                                        fxFlex="50"
                                        style="margin-right: 10px"
                                    >
                                        <mat-label
                                            >Nombre de pièces principales
                                        </mat-label>
                                        <input
                                            type="number"
                                            matInput
                                            formControlName="nbPieces"
                                            name="nbPieces"
                                            (click)="showComment()"
                                        />
                                    </mat-form-field>
                                    
                         

                            <div class="comment-info" *ngIf="isShowed">
                                <div>
                                    <img
                                        class="info-icon"
                                        [src]="
                                            'assets/images/app-images/svg/fi-rr-info.svg'
                                        "
                                    />

                                    La cuisine , la salle de bain et les
                                    toilettes ne sont pas à prendre en
                                    compte
                                </div>

                                <div
                                    class="close-icon"
                                    (click)="hideComment()"
                                >
                                    x
                                </div>
                                </div>
                                <div class="slide-toggle-section">
                                    <div class="slide-toggle-content">
                                        
                                        <mat-slide-toggle [formControl]="TerasseAppart" formControlName="TerasseAppart"
                                            >Terasse
                                        </mat-slide-toggle>
                                        <mat-slide-toggle [formControl]="GarageAppart" formControlName="GarageAppart"
                                            >Garage
                                        </mat-slide-toggle>
                                  
                                        <mat-slide-toggle [formControl]="BalconAppart" formControlName="BalconAppart"
                                            >Balcon
                                        </mat-slide-toggle>
                                       
                                        <mat-slide-toggle [formControl]="CaveAppart" formControlName="CaveAppart"
                                            >Cave
                                        </mat-slide-toggle>
                                       
                                    </div>
                                </div>
                            </div>

                            <div
                            class="stepper-btns"
                            
                        >
                            <button
                                class="mr-8"
                                mat-raised-button
                                matStepperPrevious
                                type="button"
                                color="accent"
                            >
                                Précédent
                            </button>

                            <button
                                mat-raised-button
                                type="button"
                                color="primary"
                                (click)="openEstimateDialog()"
                            >
                                Estimer mon bien
                            </button>
                        </div>
                            <div>
                                <div
                                    class="stepper-btns"
                                    *ngIf="isPriceShowed"
                                >
                                    <button
                                        class="mr-8"
                                        mat-raised-button
                                        matStepperPrevious
                                        type="button"
                                        color="accent"
                                    >
                                        Précédent
                                    </button>
                                    <div>
                                        <button
                                            class="mr-8"
                                            mat-raised-button
                                            type="button"
                                            color="primary"
                                            (click)="openEstimateDialog()"
                                        >
                                            Estimer mon bien
                                        </button>
                                        <button
                                            mat-raised-button
                                            matStepperNext
                                            type="button"
                                            color="accent"
                                            (click)="
                                                openServiceDialog(
                                                    'Séance Photo',
                                                    1,
                                                    1200
                                                )
                                            "
                                        >
                                            Suivant
                                        </button>
                                    </div>
                                </div>



                            </div>




                        </form></mat-step>
                                </div>
                                
                              
                            </form>
                            </mat-step>
                            

                        

                        <mat-step
                            [stepControl]="verticalStepperStep1"
                            state="state1"
                            *ngIf="isPriceShowed"
                        >
                            <form
                                fxLayout="column"
                                [formGroup]="verticalStepperStep1"
                            >
                                <div fxFlex="1 0 auto" fxLayout="column">
                                    <div
                                        fxLayout="column"
                                        fxLayoutAlign="center center"
                                    >
                                        <div class="photos-container">
                                            <input
                                                type="file"
                                                id="selectedFile"
                                                style="display: none"
                                                #file
                                                type="file"
                                                accept="image/*"
                                                (change)="
                                                    onFileChanged(0, file.files)
                                                "
                                            />
                                            <button
                                                class="img-upload-btn"
                                                onclick="document.getElementById('selectedFile').click();"
                                            >
                                                <img
                                                    [src]="imgUrls[0]"
                                                    *ngIf="imgUrls[0]"
                                                    class="img-upload"
                                                />

                                                <mat-icon
                                                    class="secondary-text s-48 my-icon"
                                                    *ngIf="!imgUrls[0]"
                                                    >{{
                                                        "add_a_photo"
                                                    }}</mat-icon
                                                >
                                                <span *ngIf="!imgUrls[0]">
                                                    Ajouter une photo
                                                </span>
                                            </button>

                                            <input
                                                type="file"
                                                id="selectedFile1"
                                                style="display: none"
                                                #file1
                                                type="file"
                                                accept="image/*"
                                                (change)="
                                                    onFileChanged(
                                                        1,
                                                        file1.files
                                                    )
                                                "
                                            />
                                            <button
                                                class="img-upload-btn"
                                                onclick="document.getElementById('selectedFile1').click();"
                                            >
                                                <img
                                                    [src]="imgUrls[1]"
                                                    *ngIf="imgUrls[1]"
                                                    class="img-upload"
                                                />

                                                <mat-icon
                                                    class="secondary-text s-48 my-icon"
                                                    *ngIf="!imgUrls[1]"
                                                    >{{
                                                        "add_a_photo"
                                                    }}</mat-icon
                                                >
                                                <span *ngIf="!imgUrls[1]">
                                                    Ajouter une photo
                                                </span>
                                            </button>

                                            <input
                                                type="file"
                                                id="selectedFile2"
                                                style="display: none"
                                                #file2
                                                type="file"
                                                accept="image/*"
                                                (change)="
                                                    onFileChanged(
                                                        2,
                                                        file2.files
                                                    )
                                                "
                                            />
                                            <button
                                                class="img-upload-btn"
                                                onclick="document.getElementById('selectedFile2').click();"
                                            >
                                                <img
                                                    [src]="imgUrls[2]"
                                                    *ngIf="imgUrls[2]"
                                                    class="img-upload"
                                                />

                                                <mat-icon
                                                    class="secondary-text s-48 my-icon"
                                                    *ngIf="!imgUrls[2]"
                                                    >{{
                                                        "add_a_photo"
                                                    }}</mat-icon
                                                >
                                                <span *ngIf="!imgUrls[2]">
                                                    Ajouter une photo
                                                </span>
                                            </button>

                                            <input
                                                type="file"
                                                id="selectedFile3"
                                                style="display: none"
                                                #file3
                                                type="file"
                                                accept="image/*"
                                                (change)="
                                                    onFileChanged(
                                                        3,
                                                        file3.files
                                                    )
                                                "
                                            />
                                            <button
                                                class="img-upload-btn"
                                                onclick="document.getElementById('selectedFile3').click();"
                                            >
                                                <img
                                                    [src]="imgUrls[3]"
                                                    *ngIf="imgUrls[3]"
                                                    class="img-upload"
                                                />

                                                <mat-icon
                                                    class="secondary-text s-48 my-icon"
                                                    *ngIf="!imgUrls[3]"
                                                    >{{
                                                        "add_a_photo"
                                                    }}</mat-icon
                                                >
                                                <span *ngIf="!imgUrls[3]">
                                                    Ajouter une photo
                                                </span>
                                            </button>
                                        </div>
                                    </div>

                                    <div
                                        fxLayout="column"
                                        fxLayoutAlign="center center"
                                        class="photos-service"
                                    >
                                        <h3 class="bold">
                                            Si vous avez besoin d'un
                                            photographe, vous pouvez profiter de
                                            notre service Séance Photo
                                        </h3>

                                        <button
                                            mat-raised-button="mat-raised-button"
                                            (click)="
                                                openServiceDialog(
                                                    'Séance Photo',
                                                    1,
                                                    0
                                                )
                                            "
                                            type="button"
                                            class="service-btn"
                                        >
                                            Consulter le service
                                        </button>
                                    </div>

                                    <div class="stepper-btns">
                                        <button
                                            class="mr-8"
                                            mat-raised-button
                                            matStepperPrevious
                                            type="button"
                                            color="accent"
                                        >
                                            Précédent
                                        </button>
                                        <button
                                            mat-raised-button
                                            matStepperNext
                                            type="button"
                                            color="accent"
                                            (click)="
                                                openServiceDialog(
                                                    'Home Staging',
                                                    6,
                                                    1200
                                                )
                                            "
                                        >
                                            Suivant
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </mat-step>

                        <mat-step
                            [stepControl]="verticalStepperStep1"
                            state="state1"
                            state="state1"
                            *ngIf="isPriceShowed"
                        >
                            <form
                                fxLayout="column"
                                [formGroup]="verticalStepperStep1"
                            >
                                <div fxFlex="1 0 auto" fxLayout="column">
                                    <div
                                        fxLayout="column"
                                        fxLayoutAlign="center center"
                                    ></div>

                                    <div
                                        fxLayout="column"
                                        fxLayoutAlign="center center"
                                        class="photos-service"
                                    >
                                        <h3 class="bold">
                                            Vous pouvez profiter de notre
                                            service Home Staging
                                        </h3>

                                        <button
                                            mat-raised-button="mat-raised-button"
                                            (click)="
                                                openServiceDialog(
                                                    'Home Staging',
                                                    6,
                                                    0
                                                )
                                            "
                                            type="button"
                                            class="service-btn"
                                        >
                                            Consulter le service
                                        </button>
                                    </div>

                                    <div class="stepper-btns">
                                        <button
                                            class="mr-8"
                                            mat-raised-button
                                            matStepperPrevious
                                            type="button"
                                            color="accent"
                                        >
                                            Précédent
                                        </button>
                                        <button
                                            mat-raised-button
                                            matStepperNext
                                            type="button"
                                            color="accent"
                                            (click)="
                                                openServiceDialog(
                                                    'Visite Virtuelle',
                                                    7,
                                                    1200
                                                )
                                            "
                                        >
                                            Suivant
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </mat-step>

                        <mat-step
                            [stepControl]="verticalStepperStep1"
                            state="state1"
                            *ngIf="isPriceShowed"
                        >
                            <form
                                fxLayout="column"
                                [formGroup]="verticalStepperStep1"
                            >
                                <div fxFlex="1 0 auto" fxLayout="column">
                                    <div
                                        fxLayout="column"
                                        fxLayoutAlign="center center"
                                    ></div>

                                    <div
                                        fxLayout="column"
                                        fxLayoutAlign="center center"
                                        class="photos-service"
                                    >
                                        <h3 class="bold">
                                            Vous pouvez profiter de notre
                                            service Visite Virtuelle
                                        </h3>

                                        <button
                                            mat-raised-button="mat-raised-button"
                                            (click)="
                                                openServiceDialog(
                                                    'Visite Virtuelle',
                                                    7,
                                                    0
                                                )
                                            "
                                            type="button"
                                            class="service-btn"
                                        >
                                            Consulter le service
                                        </button>
                                    </div>

                                    <div class="stepper-btns">
                                        <button
                                            class="mr-8"
                                            mat-raised-button
                                            matStepperPrevious
                                            type="button"
                                            color="accent"
                                        >
                                            Précédent
                                        </button>
                                        <button
                                            mat-raised-button
                                            matStepperNext
                                            type="button"
                                            color="accent"
                                            (click)="
                                                openServiceDialog(
                                                    'Reception et filtre des appels',
                                                    8,
                                                    1200
                                                )
                                            "
                                        >
                                            Suivant
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </mat-step>

                        <mat-step
                            [stepControl]="verticalStepperStep1"
                            state="state1"
                            *ngIf="isPriceShowed"
                        >
                            <form
                                fxLayout="column"
                                [formGroup]="verticalStepperStep1"
                            >
                                <div fxFlex="1 0 auto" fxLayout="column">
                                    <mat-form-field
                                        appearance="outline"
                                        fxFlex="100"
                                    >
                                        <mat-label
                                            >Votre numéro de téléphone
                                        </mat-label>
                                        <input
                                            matInput
                                            formControlName="telephone"
                                            name="telephone"
                                        />
                                    </mat-form-field>

                                    <div
                                        fxLayout="column"
                                        fxLayoutAlign="center center"
                                        style="margin-bottom: 20px"
                                    >
                                        <h3 class="bold">
                                            Vous pouvez profiter de notre
                                            service Reception et filtre des
                                            appels
                                        </h3>

                                        <button
                                            mat-raised-button="mat-raised-button"
                                            (click)="
                                                openServiceDialog(
                                                    'Reception et filtre des appels',
                                                    8,
                                                    0
                                                )
                                            "
                                            type="button"
                                            class="service-btn"
                                        >
                                            Consulter le service
                                        </button>
                                    </div>

                                    <div class="stepper-btns">
                                        <button
                                            class="mr-8"
                                            mat-raised-button
                                            matStepperPrevious
                                            type="button"
                                            color="accent"
                                        >
                                            Précédent
                                        </button>
                                        <button
                                            mat-raised-button
                                            type="button"
                                            (click)="openDialogLogin()"
                                            color="primary"
                                        >
                                            Ajouter le bien
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </mat-step>

                        <!-- Replace icon mat-step -->

                        <ng-template matStepperIcon="state1">
                            <mat-icon></mat-icon>
                        </ng-template>

                        <!-- Replace icon mat-step -->
                    </mat-horizontal-stepper>
                </div>
            </div>
        </div>
    </div>
</div>
