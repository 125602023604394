<div style="width:500px">
    <h2 mat-dialog-title align="center">
        Conditions particulieres
    </h2>
    <mat-dialog-content class="mat-typography" styme="margin-top:20px">
        <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Veritatis
            consectetur ut, animi tempora voluptatem ab impedit saepe ex sint rem
            consequatur voluptas perspiciatis est, quam, sed beatae architecto nisi unde
            autem modi iusto. Qui aperiam culpa exercitationem repellat praesentium
            eligendi, voluptates ipsam suscipit eos sint labore amet eveniet totam
            impedit iure dolores neque dolorum. In rem, fuga voluptatum debitis odio
            nobis sit. Facilis eius tempore eum quis? Voluptatum veniam voluptas dolores
            laborum animi autem quasi nesciunt, similique eius quibusdam consequuntur
            ipsa debitis reiciendis error harum maxime quod enim. Odit sed sunt, illo
            exercitationem, fugiat, autem molestias ex et nobis maxime dignissimos
            impedit fugit vitae magnam eaque minus. Accusantium quo nihil suscipit!
        </p>

        <p>
            Enim id ea ipsa, expedita recusandae suscipit architecto. Tempora dicta, dolorum
            dolor accusamus voluptatem maxime vero aperiam deserunt perferendis officiis
            porro doloribus expedita magni atque excepturi praesentium accusantium.
            Assumenda fuga, dolor debitis delectus earum quibusdam amet animi.
            Cupiditate rerum, soluta voluptates eveniet veritatis delectus magnam minima
            quis natus, quidem sapiente vitae facere quod nesciunt perferendis.
            Voluptatem quas unde maxime impedit quisquam aut labore, deleniti quidem
            magnam architecto ad. Libero ratione quaerat perspiciatis ipsum, natus eaque
            sapiente quos explicabo voluptas rem fugit aperiam repellendus alias sunt
            neque, facere in dolorem, nostrum exercitationem nemo ipsa eligendi. Vero
            aperiam quas doloremque maiores incidunt libero quam dolorem ad blanditiis
            earum quaerat vitae quae exercitationem officia maxime eos odit ipsa, dolor
            nostrum dolores.
        </p>

        <p>
            Sapiente unde, quibusdam doloremque placeat nam ratione eos
            est velit et, asperiores fugiat voluptatibus doloribus. Nihil deleniti eius
            harum sequi, ab, voluptatibus amet quibusdam esse ea quos tenetur alias
            placeat reprehenderit nam voluptas. Mollitia ea quisquam hic magni
            necessitatibus assumenda quae, at sit officia maxime eligendi ratione fugiat
            consequuntur nihil deleniti asperiores ex itaque inventore, odio, beatae
            soluta laudantium numquam accusantium. Quidem maiores assumenda atque
            molestias est quia nam voluptatibus facere!
        </p>

    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close>Cancel</button>
        <button
            mat-button
            mat-dialog-close
            [routerLink]="['/owner/payement/', data.id]"
        >
            J'accepte
        </button>
    </mat-dialog-actions>


</div>
