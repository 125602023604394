import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";

import { Service } from "../../../models/service.model";
import { ServiceOrdersService } from "../../../services/service-orders.service";
import { ConditionsDialogComponent } from "../../../components/conditions-dialog/conditions-dialog.component";
import { FuseConfigService } from "@fuse/services/config.service";

@Component({
    selector: "service-details",
    styleUrls: ["service-details.component.scss"],
    templateUrl: "service-details.component.html",
})
export class ServiceDetailComponent implements OnInit {
    service: Service;
    id;
    order;

    constructor(
        private route: ActivatedRoute,
        private servOrders: ServiceOrdersService,
        public dialog: MatDialog,
        private _fuseConfigService: FuseConfigService
    ) {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true,
                },
                toolbar: {
                    hidden: true,
                },
                footer: {
                    hidden: true,
                },
                sidepanel: {
                    hidden: true,
                },
            },
        };

        this.id = this.route.snapshot.paramMap.get("id");

        this.servOrders.getService(this.id).subscribe((data) => {
            this.service = data;
            console.log(data);
        });
    }

    ngOnInit = () => {};

    openDialog(): void {
        const dialogRef = this.dialog.open(ConditionsDialogComponent, {
            data: this.service,
        });

        dialogRef.afterClosed().subscribe((result) => {
            console.log("The dialog was closed");
        });
    }

    addServiceOrder = () => {
        this.order = {
            service_id: this.id,
            owner_id: 5,
        };

        this.servOrders.addServiceOrder(this.order).subscribe((data) => {
            console.log(data);
        });
    };
}
