<div id="products" class="page-layout carded fullwidth">
    <!-- TOP BACKGROUND -->
    <div class="top-bg"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">
        <!-- HEADER -->
        <div
            class="header"
            fxLayout="column"
            fxLayoutAlign="center center"
            fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="space-between center"
        >
            <!-- APP TITLE -->
            <div
                class="logo mb-24 mb-md-0"
                fxLayout="row"
                fxLayoutAlign="start center"
            >
                <mat-icon class="logo-icon s-32 mr-16">card_travel</mat-icon>
                <span class="logo-text h1 bold"> Services </span>
            </div>
            <!-- / APP TITLE -->
        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->

        <div class="products">
            <div class="products__list">
                <service-item
                    *ngFor="let service of servicesList"
                    [service]="service"
                >
                </service-item>
            </div>
        </div>

        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>
