import { Component, OnInit, ViewEncapsulation } from "@angular/core";

import { fuseAnimations } from "@fuse/animations";
import { FuseConfigService } from "@fuse/services/config.service";

@Component({
    selector: "service-orders-list",
    templateUrl: "./service-orders-list.component.html",
    styleUrls: ["./service-orders-list.component.scss"],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class ServiceOrdersListComponent implements OnInit {
    orders;
    displayedColumns: string[] = [
        "service_name",
        "service_property",
        "approved",
        "company_name",
        "actions",
    ];
    dataSource = [];

    constructor(private _fuseConfigService: FuseConfigService) {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true,
                },
                toolbar: {
                    hidden: true,
                },
                footer: {
                    hidden: true,
                },
                sidepanel: {
                    hidden: true,
                },
            },
        };

        this.dataSource = [
            {
                service_name: "Seance Photos",
                service_property: "Eaton Garth House",
                approved: "Oui",
                company_name: "Photos Company",
            },
            {
                service_name: "Home Staging",
                service_property: "Eaton Garth House",
                approved: "Non",
                company_name: "Home Staging Company",
            },
            {
                service_name: "Negociateur Professionnel",
                service_property: "Diamond Manor Apartment",
                approved: "Oui",
                company_name: "Company 1",
            },
            {
                service_name: "Seance Photos",
                service_property: "Diamond Manor Apartment",
                approved: "Oui",
                company_name: "Photos Company",
            },
        ];
    }

    ngOnInit(): void {
        /*   this.serv.getOrders().subscribe((data) => {
              this.orders = data;
          });

         this.biensService.getBiens().subscribe((data) => {
              this.dataSource = data;
          });*/
    }
}
